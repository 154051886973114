
import Vue from "vue";
import Auth from "@/lib/Auth";
export default Vue.extend({
  name: "MobileMenu",
  data: () => ({
    MenuTopo: false,
    showMenuTransfPenendetes: false,
    isMobile: false,
  }),
  created() {
    this.isMobile = this.detectMobile();
    const us = Auth.getUser();
    this.showMenuTransfPenendetes = us.data.apiStatus;
  },
  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
});
