
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import { Overlay } from "@/lib/Overlay";
import GraficoGGR from "@/views/ggr/Grafico.vue";
export default Vue.extend({
  name: "mainGGR",

  data() {
    return {
      ModuloInfo: [],
      pinCode: "",
      dialogPIN: false,
      loadingData: true,
      isAffiliate: true,
      authorired: true,
      lang: navigator.language,
      errors: [],
      moment: moment,
      parent: 0,
      isMobile: false,
      btnLoading: false,
      breadcrumbs: [
        {
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.Affiliates"),
          disabled: false,
          href: "/afiliados",
        },
        {
          text: "GGR",
          disabled: true,
          href: "/afiliados",
        }
      ],
    };
  },
  components: {
    GraficoGGR,
  },
  created() {
    this.isMobile = this.detectMobile();
    this.loadInfo();
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.updateBreadcrumbs();
      },
      immediate: true
    }
  },
  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    loadInfo() {
      this.loadingData = true;
      Api.get("/v2/client/ggr/info")
        .then((response) => {
          this.ModuloInfo = response.data.body;

          this.isAffiliate = response.data.body.isAffiliate;
          this.parent = response.data.body.parent;
          sessionStorage.setItem("ggr", response.data.body.parent);

          if (response.data.body.parent != "0") {
            if (response.data.body.config.status === "0") {
              this.authorired = false;
            } else {
              this.authorired = true;
            }
          }

          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            Snackbar.show(data.body.message, "error");
          }
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    authorizeGGR() {
      if (this.pinCode.length != 6) {
        this.focusPin();
        Snackbar.show("Informe seu PIN", "error");
        return false;
      }
      this.dialogPIN = false;
      this.btnLoading = true;
      Overlay.show();
      Api.put("v2/client/ggr/authorize", { transactionPin: this.pinCode })
        .then((response) => {
          Swal.fire({
            text: "Débito de GGR Autorizado",
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          this.pinCode = "";
          this.loadInfo();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          this.pinCode = "";
          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
        })
        .finally(() => {
          this.btnLoading = false;

          Overlay.hide();
        });
    },
    closeDialogPin() {
      this.pinCode = "";
      this.dialogPIN = false;
    },
    focusPin() {
      this.$nextTick(() => {
        const wrapper = this.$refs.pincode as any;
        const cells = wrapper.$el.getElementsByClassName(
          "vue-pincode-input"
        ) as any;

        window.setTimeout(() => wrapper?.focus(), 0);
      });
    },
    updateBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.Affiliates"),
          disabled: false,
          href: "/afiliados",
        },
        {
          text: "GGR",
          disabled: true,
          href: "/afiliados",
        }
      ];
    }
  },
});
