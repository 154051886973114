import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import Auth from "../lib/Auth";

class Api {
  init() {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_API,
    });
    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config: AxiosRequestConfig) {
      const token: string = sessionStorage.token;

      config.headers.Authorization = token ? `Bearer ${token}` : "";
      config.headers.AUTH_TOKEN = process.env.VUE_APP_API_ACCESS_TOKEN;
      return config;
    });

    // Add a response interceptor
    instance.interceptors.response.use(
      function (response: AxiosResponse) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        const status = Number(error.response.status);

        if (status === 401) {
          Auth.logout();
        }

        return Promise.reject(error);
      }
    );

    return instance;
  }
}

export default new Api().init();
