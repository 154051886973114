
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
export default Vue.extend({
  name: "Servicos",

  data() {
    return {
      ModuloInfo: [],
      loadingData: true,

      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
    };
  },
  created() {
    this.isMobile = this.detectMobile();
  },

  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
});
