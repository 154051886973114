
import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import VueMask from "v-mask";

Vue.use(VueMask);

export default Vue.extend({
  name: "Recargas",

  data() {
    return {
      showCPFAssinante: false,
      codigoAssinante: "",
      comoFuncionaPanel: 1,
      dialogComprovante: false,
      isMobile: false,
      pinCode: "",
      ModuloInfo: [],
      dialogPIN: false,
      hasOperadoraSelecionada: false,
      dialogConfirmaRecarga: false,
      recargaStep: 1,
      telefone: "",
      telefoneConf: "",
      listaOperadoras: [],
      operadoraSelecionada: [],
      recargasRecentes: [],
      valid: true,
      loadingData: true,
      loadingDataOperadoras: true,
      lang: navigator.language,
      errors: [],
      btnLoading: false,
      moment: moment,
      dataComprovante: "",
      breadcrumbs: [
        {
          text: "Serviços",
          disabled: false,
          href: "/servicos",
        },
        {
          text: "Recarga de TV",
          disabled: true,
          href: "/recargas/tv",
        },
      ],
      telefoneRecaga: "",
      valorRecargaSelecionada: "",
      operadoraValores: [],
      headersRecargas: [
        {
          text: "",
          value: "operadoraLogo",
          sortable: false,
          width: "30px",
          fixed: true,
        },
        { text: "Data", value: "data", sortable: false },

        { text: "Valor", value: "valorFormatado", sortable: false },
        { text: "", value: "comprovante", sortable: false },
      ],
    };
  },

  created() {
    this.isMobile = this.detectMobile();
    this.telefone = Auth.getUserData()["telefone"];
    this.moduloInfo();
  },

  methods: {
    openDialogComprovante(data: any) {
      this.dataComprovante = data;
      this.dialogComprovante = true;
    },
    openDialogPin() {
      this.dialogConfirmaRecarga = false;

      this.dialogPIN = true;
      this.$nextTick(() => {
        this.focusPin();
      });
    },
    closeDialogPin() {
      (this.pinCode = ""), (this.dialogPIN = false);
    },
    focusPin() {
      this.$nextTick(() => {
        const wrapper = this.$refs.pincode as any;

        window.setTimeout(() => wrapper?.focus(), 0);
      });
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    getOperadoras() {
      const ddd = 11;
      Overlay.show();
      this.btnLoading = true;
      this.loadingDataOperadoras = true;
      Api.get("/v2/client/recargas/operadoras/3/" + ddd)
        .then((response) => {
          this.listaOperadoras = response.data.body.rows;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            Snackbar.show(data.body.error, "error");
          }
        })
        .finally(() => {
          this.btnLoading = false;
          this.loadingDataOperadoras = false;
          Overlay.hide();
        });
    },
    getOperadoraValores(operadora: string) {
      Overlay.show();

      this.btnLoading = true;
      const ddd = 11;
      Api.get("/v2/client/recargas/valores/" + operadora + "/" + ddd)
        .then((response) => {
          this.operadoraValores = response.data.body.rows;
          this.recargaStep = 2;
          this.hasOperadoraSelecionada = true;
          this.comoFuncionaPanel = 0;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            Snackbar.show(data.body.error, "error");
          }
        })
        .finally(() => {
          this.btnLoading = false;
          Overlay.hide();
        });
    },
    efetuaRecarga() {
      if (this.pinCode.length != 6) {
        this.focusPin();
        Snackbar.show("Informe seu PIN", "error");
        return false;
      }
      this.errors = [];
      this.btnLoading = true;
      const datosOperadora = JSON.parse(
        JSON.stringify(this.operadoraSelecionada)
      );
      this.dialogPIN = false;

      Overlay.show();
      Api.post("/v2/client/recargas/efetiva", {
        telefone: this.telefone,
        valor: this.valorRecargaSelecionada,
        operadoraId: datosOperadora.OperadoraId,
        operadoraNome: datosOperadora.Nome,
        categoria: datosOperadora.CategoriaRecargaOperadora,
        codigoAssinante: this.codigoAssinante,
        transactionPin: this.pinCode,
      })
        .then((response) => {
          //
          this.dataComprovante = response.data.body.comprovante;
          this.dialogComprovante = true;
          this.recargaStep = 1;
          this.moduloInfo();
          this.dialogPIN = false;
          this.pinCode = "";
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          // const status = response.status;
          const data = response.data;

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          this.pinCode = "";
        })
        .finally(() => {
          this.btnLoading = false;
          this.dialogPIN = false;
          Overlay.hide();
        });
    },
    openCampoCPFAssinante(operadora: any) {
      this.operadoraSelecionada = operadora;
      this.codigoAssinante = "";
      this.showCPFAssinante = true;
    },
    selecionaOperadora(data: any) {
      if (this.codigoAssinante.length < 3) {
        Snackbar.show("Informe o CPF ou Código do Assinante", "error");
        return false;
      }

      const idOperadora = JSON.parse(data.OperadoraId);
      this.getOperadoraValores(idOperadora);
    },
    selecionaValorRecarga(valor: string) {
      this.valorRecargaSelecionada = valor;
      this.dialogConfirmaRecarga = true;
    },
    moduloInfo() {
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/client/recargas/info/3")
        .then((response) => {
          this.ModuloInfo = response.data.body.moduloInfo;
          this.recargasRecentes = response.data.body.recargasRecentes;
          this.getOperadoras();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.loadingData = false;
        });
    },
  },
});
