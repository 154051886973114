var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-list',[_c('v-list-item-group',[_c('v-list-item',{attrs:{"two-line":""},on:{"click":function($event){return _vm.openDialogChangeFoto()}}},[_c('v-list-item-avatar',{attrs:{"size":"80"}},[_c('v-img',{attrs:{"src":_vm.profileAvatar + '?v=' + _vm.rand}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("components.preCadastro.template.form.Profilepicture")))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t("components.preCadastro.template.form.Aphotohelpspersonalizeyouraccount")))])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","fullscreen":_vm.isMobile,"max-width":_vm.isMobile ? 0 : 450},model:{value:(_vm.dialogChangeFoto),callback:function ($$v) {_vm.dialogChangeFoto=$$v},expression:"dialogChangeFoto"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialogChangeFoto = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("components.preCadastro.template.form.EditPhoto")))])],1),_c('v-card-text',[_c('div',{staticClass:"upload-example"},[_c('cropper',{ref:"cropper",staticClass:"twitter-cropper",attrs:{"background-class":"twitter-cropper__background","foreground-class":"twitter-cropper__foreground","image-restriction":"stencil","stencil-size":_vm.stencilSize,"stencil-props":{
              lines: {},
              handlers: {},
              movable: false,
              scalable: false,
              aspectRatio: 1,
              previewClass: 'twitter-cropper__stencil',
            },"resize-image":{
              adjustStencil: false,
            },"transitions":false,"debounce":false,"default-size":_vm.defaultSize,"min-width":150,"min-height":150,"src":_vm.image.src},on:{"change":_vm.onChange}}),_c('navigation',{attrs:{"zoom":_vm.zoom},on:{"change":_vm.onZoom}}),_c('div',{staticClass:"button-wrapper"},[_c('v-btn',{staticClass:"text-none mb-5",attrs:{"color":"purple darken-3","block":"","text":""},on:{"click":function($event){return _vm.$refs.uploader.click()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cloud-upload-outline ")]),_vm._v(" "+_vm._s(_vm.$t("components.preCadastro.template.form.Selectaphoto"))+" ")],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file"},on:{"change":function($event){return _vm.loadImage($event)}}})],1)],1)]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"purple darken-3","text":""},on:{"click":function($event){_vm.dialogChangeFoto = false}}},[_vm._v(" "+_vm._s(_vm.$t("components.preCadastro.template.form.CANCEL"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"purple darken-3","elevation":"0","dark":""},on:{"click":function($event){return _vm.crop()}}},[_vm._v(" "+_vm._s(_vm.$t("components.preCadastro.template.form.SAVE"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }