
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosError, AxiosResponse } from "axios";
import Swal from "sweetalert2";
import { Snackbar } from "@/lib/Snackbar";

import moment from "moment";

export interface cobrancasClientesEdit {
  id?: number;
  createdAt?: string;
  uuid?: string;
  name?: string;
  taxId?: string;
  phone?: string;
  email?: string;
  street?: string;
  number?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

export default Vue.extend({
  data: () => ({
    dialogEditCliente: false,
    formEdit: {} as cobrancasClientesEdit,
    btnLoading: false,
    lang: navigator.language,
    id: Number,
    errors: [],
    moment: moment,
    isMobile: false,
    readonlyCidade: false,
    readonlyUF: false,
  }),
  created() {
    this.isMobile = this.detectMobile();
  },
  methods: {
    show() {
      this.formEdit = JSON.parse(localStorage.getItem("editCliente") || "{}");
      this.$nextTick(() => {
        /*
        if (this.formEdit.nascimento != undefined) {
          this.formEdit.nascimento = moment(this.formEdit.nascimento).format(
            "DD/MM/YYYY"
          );
        }
        */
        this.dialogEditCliente = true;
      });
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    cancelaEdit() {
      localStorage.removeItem("editCliente");
      this.dialogEditCliente = false;
    },
    put() {
      this.btnLoading = true;
      this.errors = [];

      Api.put(
        "/v2/client/edit/" + this.formEdit.uuid,
        this.formEdit
      )
        .then((response: AxiosResponse<cobrancasClientesEdit>) => {
          if (response.status == 201) {
            Snackbar.show("Cliente Alterado com Sucesso!");
          }
          this.$emit("callmethod", 0);
          localStorage.removeItem("editCliente");
          this.dialogEditCliente = false;
          this.formEdit = {} as cobrancasClientesEdit;
        })
        .catch((e: AxiosError) => {
          const response = e.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status == 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    reset() {
      //this.$refs.form?.reset();
    },
    checkCEP: function (token: string) {
      this.errors = [];
      if (this.formEdit.zipCode?.length == 9) {
        this.btnLoading = true;
        const zipCode = this.formEdit.zipCode;
        const ulrConsult = process.env.VUE_APP_API + "/v2/client/address/" +zipCode;
        // Overlay.show();
        Api.get( ulrConsult )
          .then((response) => {
            this.formEdit.city = response.data.body.localidade;
            this.formEdit.state = response.data.body.uf;

            if (this.formEdit.state?.length == 2) {
              this.readonlyUF = true;
            }
            this.$nextTick(() => {
              const wrapper = this.$refs.refLogradouro as any;

              window.setTimeout(() => wrapper?.focus(), 0);
            });

            // this.storage();
          })
          .catch((error: any) => {
            const response = error.response as AxiosResponse;
            const status = response.status;
            const data = response.data;

            if (status === 422) {
              this.errors = data.body.errors;
            } else {
              Swal.fire({
                text: data.body.error,
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
            }
          })
          .finally(() => {
            this.btnLoading = false;
            //  console.log("finalizou");
          });
      }
    },
  },
});
