import store from "@/store";

export class Overlay {
  static show(): void {
    store.dispatch("showOverlay", {});
  }
  static hide(): void {
    store.dispatch("hideOverlay", {});
  }
}
