
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { PagamentoPix } from "@/models/PagamentoPix";
import { VMoney } from "v-money";
import Swal from "sweetalert2";
import { Snackbar } from "@/lib/Snackbar";
import jwtDecode from "jwt-decode";
import router from "@/router";
import Auth from "@/lib/Auth";
export default Vue.extend({
  name: "TransferirPixEMV",

  data() {
    return {
      dialogPixEmProcessamento: false,
      dialogRevisaoTransferPix: false,
      formPagtoPix: {} as PagamentoPix,
      ModuloInfo: [],
      emv: "",
      alert: {} as Alert,
      loadingData: true,
      btnloading: false,
      btnDisabled: true,
      dialogPixCopyPaste: false,
      isMobile: false,
      valorPagamento: 0,
      curStep: 2,
      lang: navigator.language,
      errors: [] as any,
      moment: moment,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      emvData: {},
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.PixArea"), 
          disabled: false,
          href: "/pix",
        },
        {
          text: this.$t("components.preCadastro.template.form.PayPix"),
          disabled: true,
          href: "/pix/menuPix",
        },
      ],
      ckStatusPagto: false,
      progressPixCkinterval: 0,
      progressTempoCkPix: 0,
      idPixChecking: "",
      qtdCheckStatus: 0,
      valid: true,
      dialogDefineValorPagto: false,
      loadingDataEMV: true,
      transactionPin: "",
    };
  },

  created() {
    this.isMobile = this.detectMobile();

    if (!Auth.mfaEnabled) {
      router.push("/");
    } else {
      if (
        sessionStorage.emvData == undefined ||
        sessionStorage.emvData == null
      ) {
        Snackbar.show("QRCode inválido", "error");
        router.push("/pix/transferir/emv");
      }
      this.readEMVJWT();
      this.moduloInfo();
    }
  },

  methods: {
    readEMVJWT() {
      const emv = jwtDecode(sessionStorage.getItem("emvData")) as any;
      console.log(emv.data);
      this.emvData = emv.data;
      this.valorPagamento = emv.data.payment.amount;

      this.loadingDataEMV = false;

      console.log(this.loadingDataEMV);
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    cancelaPagamento() {
      this.transactionPin = "";

      this.dialogRevisaoTransferPix = false;
    },
    editaValorPagamento() {
      this.dialogDefineValorPagto = true;
      this.$nextTick(() => {
        const wrapper = this.$refs.valorTransferencia as any;
        window.setTimeout(() => wrapper?.focus(), 0);
      });
    },
    showRevisao() {
      if (
        this.valorPagamento == null ||
        this.valorPagamento == 0 ||
        this.valorPagamento.toString() === "" ||
        this.valorPagamento.toString() === "R$ 0,00" ||
        this.valorPagamento.toString() === "0,00" ||
        this.valorPagamento.toString() === "0.00"
      ) {
        Snackbar.show("Informe o valor", "error");
        this.editaValorPagamento();
        this.$nextTick(() => {
          const wrapper = this.$refs.valorPagamento as any;

          window.setTimeout(() => wrapper?.focus(), 0);
        });
      } else {
        this.dialogRevisaoTransferPix = true;
        this.$nextTick(() => {
          const wrapper = this.$refs.pincode as any;

          window.setTimeout(() => wrapper?.focus(), 0);
        });
      }
    },
    moduloInfo() {
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/client/financeiro/pix/info")
        .then((response) => {
          this.ModuloInfo = response.data.body.moduloInfo;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    ckLenghtEMV() {
      this.showAlert();
      if (this.emv.length > 50) {
        this.btnDisabled = false;
      }
    },
    formataValor(data: number) {
      const valor = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(data);

      return valor.toString().replace("R$", "").trim();
    },

    defineValorPix() {
      if (
        this.valorPagamento == null ||
        this.valorPagamento.toString() === "" ||
        this.valorPagamento.toString() === "R$ 0,00" ||
        this.valorPagamento.toString() === "0,00" ||
        this.valorPagamento.toString() === "0.00"
      ) {
        Snackbar.show("Valor inválido", "error");
      } else {
        this.dialogDefineValorPagto = false;
      }
    },
    transferePix() {
      this.btnloading = true;
      this.errors = [];
      if (
        this.transactionPin === "" ||
        this.transactionPin.toString().length < 6
      ) {
        this.errors["transactionPin"] = "Informe seu PIN de transações";
        Snackbar.show("Informe seu PIN de transações", "error");
        this.btnloading = false;
        return false;
      }
      if (
        this.valorPagamento.toString() === "" ||
        this.valorPagamento.toString() === "0.00" ||
        this.valorPagamento.toString() === "0,00"
      ) {
        Snackbar.show("Informe o valor", "error");
        this.btnloading = false;
        return false;
      }
      Overlay.show();
      this.btnloading = true;
      this.dialogRevisaoTransferPix = false;
      //this.dialogProcessandoPix = true;
      Api.post("/v2/client/financeiro/pix/qrcode/process", {
        emv: sessionStorage.getItem("emvData"),
        amount: this.valorPagamento,
        transactionPin: this.transactionPin,
      })
        .then((response) => {
          this.dialogRevisaoTransferPix = false;
          this.dialogPixEmProcessamento = true;
          sessionStorage.removeItem("emvData");
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            this.dialogRevisaoTransferPix = true;
            //this.dialogProcessandoPix = false;
            Swal.fire({
              title: "Opps",
              text: data.body.error,
              icon: "error",
            });
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.btnloading = false;
        });
    },
    resetModulo() {
      this.emv = "";
      sessionStorage.removeItem("emvData");
    },

    showAlert(show = false, message = "", type = "error"): void {
      this.alert = {
        show,
        type,
        message,
      };
    },
  },
  directives: { money: VMoney },
});
