
import Vue from "vue";
import Api from "@/services/Api";
import Auth from "@/lib/Auth";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { DepositoTED } from "@/models/DepositoTED";

import Swal from "sweetalert2";
import { VMoney } from "v-money";
export default Vue.extend({
  name: "AdicionarSaldoTED",

  data() {
    return {
      hasComprovante: false,
      reveal: false,
      imageSrc: "",
      form: {} as DepositoTED,
      errors: [],
      valid: true,
      btnLoading: false,
      panelAtividadeRecentes: 0,
      isMobile: false,
      dialogInformarDeposito: false,
      dadosComprovanteBoleto: [],
      ModuloInfo: [],
      ContasDisponivels: [],
      AtividadeRecente: [],
      ContaSelecionada: [],
      loadingData: true,
      lang: navigator.language,
      alert: {} as Alert,
      moment: moment,
      dadosUsuario: {},
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },

      selectedFile: null,
    };
  },
  created() {
    this.moduloInfo();
    this.dadosUsuario = Auth.getUserData();
    Swal.fire({
      html: "<small><p>Não aceitamos depósitos por envelope.</p><p>Valor mínimo de recarga é de R$ 10,00</p><p>Só faça recargas de uma conta que esteja em seu nome (mesma titularidade).</p></small>",
      icon: "warning",
      confirmButtonText: "Entendi",

      showConfirmButton: true,
      customClass: {
        confirmButton: "d-block",
      },
    });
  },
  computed: {},
  methods: {
    removeComprovante() {
      this.form.comprovante = "";
      this.hasComprovante = false;
    },
    handlerFileUploader(e: any) {
      this.selectedFile = e.target.files[0];

      const reader = new FileReader();

      (reader.onload = (e) => {
        this.form.comprovante = reader.result as string;
        this.hasComprovante = true;
      }),
        reader.readAsDataURL(e.target.files[0]);
    },

    ckStatusAlert(status: string) {
      if (status === "PENDENTE") {
        return "warning";
      }
      if (status === "RECUSADO") {
        return "error";
      }
      if (status === "APROVADO") {
        return "success";
      }
    },

    ckIconStatus(status: string) {
      if (status === "PENDENTE") {
        return "mdi mdi-clock-outline";
      }
      if (status === "RECUSADO") {
        return "mdi mdi-block-helper";
      }
      if (status === "APROVADO") {
        return "mdi mdi-check-circle-outline";
      }
    },
    ckStatusChip(status: string) {
      if (status === "PENDENTE") {
        return "orange";
      }
      if (status === "RECUSADO") {
        return "red";
      }
      if (status === "APROVADO") {
        return "green";
      }
    },

    SelecionaConta(data: any) {
      this.ContaSelecionada = data;
      this.form.conta = data.codigo;

      this.hasComprovante = false;
      this.form.comprovante = "";
      this.reveal = true;
      this.$nextTick(() => {
        this.form.valor = "";
        const wrapper = this.$refs.valorDeposito as any;
        window.setTimeout(() => wrapper?.focus(), 100);
      });
    },

    moduloInfo() {
      const id = this.$route.params.id;
      Overlay.show();
      this.showAlert();
      this.loadingData = true;
      Api.get("/v2/client/financeiro/adicionar/deposito/info")
        .then((response) => {
          this.ModuloInfo = response.data.body.moduloInfo;
          this.ContasDisponivels = response.data.body.bancos;
          this.AtividadeRecente = response.data.body.depositosRecentes;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            this.showAlert(true, data.body.message);
          }
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    postData(token: string): void {
      this.errors = [];
      this.btnLoading = true;
      Overlay.show();
      Api.post("/v2/client/financeiro/adicionar/deposito/informar", this.form)
        .then((response) => {
          //

          Swal.fire({
            text: response.data.body.message,
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          this.moduloInfo();
          this.reveal = false;
          this.form.valor = "";
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
        })
        .finally(() => {
          this.btnLoading = false;

          Overlay.hide();
        });
    },

    showAlert(show = false, message = "", type = "error"): void {
      this.alert = {
        show,
        type,
        message,
      };
    },
  },
  directives: { money: VMoney },
});
