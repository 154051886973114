
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import { TableOptions } from "@/models/TableOptions";

export interface RelatorioPix {
  data?: string;
  transactionId?: string;
  valorOriginal?: string;
  valorOriginalFormatado?: string;
  valorPago?: string;
  valorPagoFormatado?: string;
  status?: string;
  dataPagamento?: number;
  NomePagador?: string;
}

export default Vue.extend({

  
  name: "RelatorioPix",
  
  data() {
    return {
      tableKey: 0,
      btnLoadingResent: false,
      dialogWebhooks: false,
      hasLoadedData: false,
      dialogComprovantePixRecebido: false,
      dadosComprovantePixRecebido: [] as any,
      menuInitialDate: false,
      menuFinalDate: false,
      menuInitialDateMobile: false,
      ModuloInfo: [],
      loadingData: true,
      search: "",
      loadingDesserts: false,
      lang: navigator.language,
      stats: [],
      errors: [] as any,
      moment: moment,
      isMobile: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      options: {} as TableOptions,
      selectedEntryStatusPix: { id: "APROVADO", descricao: "APROVADO" },
      statusPix: [
        { id: "TODOS", descricao: "Todos" },
        { id: "APROVADO", descricao: "Aprovados" },
        { id: "PENDENTE", descricao: "Pendente" },
        { id: "DEVOLVIDO", descricao: "Devolvidos" },
        { id: "EXPIRADO", descricao: "Expirados" },
      ],
      queryField: [
        { id: "transactionE2E", descricao: "Id Pix (E2E)" },
        { id: "transactionId", descricao: "ID" },
        { id: "externalId", descricao: "ID Externo" },
        { id: "payerDocument", descricao: "Documento Pagador" }
      ],
      selectedEntryQueryField: { id: "transactionE2E", descricao: "Id Pix (E2E)" },
      headerTranslations: {},
      translatedStatusPix: [] as { id: string; descricao: string; }[],
      translatedQueryField: [] as  { id: string; descricao: string; }[],
      headers: [
        { 
          text: "", 
          value: "status", 
          sortable: false },
        { 
          text: "components.RelatorioPix.template.DataTable.Issuance", 
          value: "data", 
          sortable: false },
        { 
          text: "components.RelatorioPix.template.DataTable.IDIDEX", 
          value: "transactionId", 
          sortable: false},
        { 
          text: "components.RelatorioPix.template.DataTable.Value", 
          value: "valorOriginalFormatado", 
          sortable: false, 
          align: "right" },
        { 
          text: "components.RelatorioPix.template.DataTable.Expiration", 
          value: "expires", 
          sortable: false, 
          align: "right" },
        {
          text: "components.RelatorioPix.template.DataTable.Payment",
          value: "dataPagamento",
          sortable: false,
          align: "right",
        },
        {
          text: "components.RelatorioPix.template.DataTable.Paid",
          value: "valorPagoFormatado",
          sortable: false,
          align: "right",
        },
        {
          text: "",
          value: "valor",
          sortable: false,
        },
      ],
      desserts: new Array<RelatorioPix>(),
      totalDesserts: 0,
      timeout: 0,
      startPeriod: moment().add(-1, "hours").format("YYYY-MM-DDTHH:mm"),
      endPeriod: moment().format("YYYY-MM-DDT23:59"),
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      defaultStartDate: moment().format("YYYY-MM-DD"),
      defaultEndDate: moment().format("YYYY-MM-DD"),
      maxDate: moment().format("YYYY-MM-DD"),
      btnDownloadDisabled: true,
      btnDownloadLoading: false,
      toExport: false,
      webhooks: {
        webhook: {
          id: "27033002",
          createdAt: "2024-03-07 11:52:07",
          type: "COBPIX",
          createdAt_ano: "2024",
          createdAt_mes: "3",
          createdAt_date: "2024-03-07",
          webhook_project_id: "22",
          clientId: "1",
          transactionId: "63510ABD51FB1B7F",
          url: "https://financeiro.invizza.com/tests/webook.php",
          sendStatus: "2",
          sendCount: "3",
        },
        attemps: {
          count: 3,
          rows: [
            {
              id: "20737407",
              webhook: "27033002",
              sendDate: "2024-03-07 11:54:14",
              sendDate_ano: "2024",
              sendDate_mes: "3",
              sendDate_date: "2024-03-07",
              sendBody:
                '{"requestBody":{"transactionType":"RECEIVEPIX","transactionId":"63510ABD51FB1B7F","external_id":"92271b39-494d-4def-98c1-648386e38f1c","amount":1,"dateApproval":"2024-03-07 11:54:14","payerName":"JOAO FERREIRA FILHO","creditParty":{"userId":null,"email":"edilson@invizza.com","taxId":"11935552000108","name":"EDILSON MOURA DA SILVA"},"debitParty":{"account":"BCO BRADESCO S.A.","bank":"BCO BRADESCO S.A.","branch":"953","taxId":"80548105391","name":"JOAO FERREIRA FILHO"},"authentication":"E60746948202403071454A9530hA97kc"}}',
              sendHeader:
                '["Verify-Signature: t=1709823254,vsign=3cc021fce5ed983f611e8dc6e9ae7c429e07555dcc4ac580aadf53e44751c844","Content-Type: application\\/json","Authorization: Basic aW52aXp6YTppbnZpenphQDEyMzQ1Ng=="]',
              responseHttpStatus: "200",
              responseBody: null,
              executionTime: "0.606188",
            },
            {
              id: "21510690",
              webhook: "27033002",
              sendDate: "2024-03-15 15:38:42",
              sendDate_ano: "2024",
              sendDate_mes: "3",
              sendDate_date: "2024-03-15",
              sendBody:
                '{"requestBody":{"transactionType":"RECEIVEPIX","transactionId":"63510ABD51FB1B7F","external_id":"92271b39-494d-4def-98c1-648386e38f1c","amount":1,"dateApproval":"2024-03-07 11:54:14","payerName":"JOAO FERREIRA FILHO","creditParty":{"userId":null,"email":"edilson@invizza.com","taxId":"11935552000108","name":"EDILSON MOURA DA SILVA"},"debitParty":{"account":"BCO BRADESCO S.A.","bank":"BCO BRADESCO S.A.","branch":"953","taxId":"80548105391","name":"JOAO FERREIRA FILHO"},"authentication":"E60746948202403071454A9530hA97kc"}}',
              sendHeader:
                '["Verify-Signature: t=1710527922,vsign=9d1da194e1662931aa550616d7cdef5d0e5e5fc7ed23a1f8099734ff42bfaa80","Content-Type: application\\/json","Authorization: Basic aW52aXp6YTppbnZpenphQDEyMzQ1Ng=="]',
              responseHttpStatus: "200",
              responseBody: null,
              executionTime: "0.707154",
            },
            {
              id: "21510702",
              webhook: "27033002",
              sendDate: "2024-03-15 15:38:56",
              sendDate_ano: "2024",
              sendDate_mes: "3",
              sendDate_date: "2024-03-15",
              sendBody:
                '{"requestBody":{"transactionType":"RECEIVEPIX","transactionId":"63510ABD51FB1B7F","external_id":"92271b39-494d-4def-98c1-648386e38f1c","amount":1,"dateApproval":"2024-03-07 11:54:14","payerName":"JOAO FERREIRA FILHO","creditParty":{"userId":null,"email":"edilson@invizza.com","taxId":"11935552000108","name":"EDILSON MOURA DA SILVA"},"debitParty":{"account":"BCO BRADESCO S.A.","bank":"BCO BRADESCO S.A.","branch":"953","taxId":"80548105391","name":"JOAO FERREIRA FILHO"},"authentication":"E60746948202403071454A9530hA97kc"}}',
              sendHeader:
                '["Verify-Signature: t=1710527936,vsign=820376a6e6acfa63247c03fdd17d1acf42a90848e4ac7965d0052d6227bf6597","Content-Type: application\\/json","Authorization: Basic aW52aXp6YTppbnZpenphQDEyMzQ1Ng=="]',
              responseHttpStatus: "200",
              responseBody: null,
              executionTime: "0.550223",
            },
          ],
        },
      },
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.translateStatusPix();
    this.translateHeaders();
    this.translateQueryField();
  },

  methods: {
    translateStatusPix() {
      this.translatedStatusPix = this.statusPix.map(status => {
        const translationKey = `components.RelatorioPix.template.StatusPix.${status.descricao}`;
    
        return {
          id: status.id,
          descricao: this.$t(translationKey) as string
        };
      });
    },
    translateQueryField() {
      this.translatedQueryField = this.queryField.map(status => {
        const translationKey = `components.RelatorioPix.template.queryField.${status.id}`;
      
        return {
          id: status.id,
          descricao: this.$t(translationKey) as string
        };
      });
    },
    translateHeaders() {
      this.headers.forEach(header => {
        this.$set(this.headerTranslations, header.text, this.$t(header.text));
      });
    },
    next(page: number) {
      this.page = page;
      this.loadDatas();
    },
    getWebhooksHistory(transactionId: string) {
      Overlay.show();
      Api.get("/v2/client/webhooks/" + transactionId + "/view")
        .then((response) => {
          this.webhooks = response.data.body;
          this.dialogWebhooks = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            Snackbar.show(data.body.error, "error");
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    resendWebhook(transactionId: string) {
      this.btnLoadingResent = true;
      Overlay.show();
      Api.get("/v2/client/webhooks/" + transactionId + "/send")
        .then((response) => {
          this.getWebhooksHistory(transactionId);
          Snackbar.show("Webhook reenviado", "success");
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            Snackbar.show(data.body.error, "error");
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.btnLoadingResent = false;
          Overlay.hide();
        });
    },
    downloadReport() {
      this.btnDownloadLoading = true;
      Overlay.show();

      Api.get("/v2/client/financeiro/pix/relatorios/recebidos", {
        params: {
          startDate: this.startPeriod,
          endDate: this.endPeriod,
          query: this.search,
          status: this.selectedEntryStatusPix.id,
          export: true,
          queryField:this.selectedEntryQueryField.id
        },
        responseType: "arraybuffer",
      })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            "pix_recebidos_" +
            this.selectedEntryStatusPix.id +
            "-" +
            this.startPeriod +
            "-" +
            this.endPeriod +
            ".xlsx";
          link.click();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.btnDownloadLoading = false;
        });
    },
    doDelayedSearch() {
      this.page = 1;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.loadDatas();
      }, 1000);
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    loadDatas() {
      const intervalo = Math.round(
        (new Date(this.endPeriod).getTime() -
          new Date(this.startPeriod).getTime()) /
          (1000 * 3600 * 24)
      );

      if (intervalo > 31) {
        Snackbar.show(
          "O intervalo de pesquisa não pode ser superior a 31 dias",
          "error"
        );
        return false;
      }
      this.hasLoadedData = true;
      Overlay.show();
      this.loadingData = true;

      this.loadingDesserts = true;
      Api.get("/v2/client/financeiro/pix/relatorios/recebidos", {
        params: {
          startDate: this.startPeriod,
          endDate: this.endPeriod,
          query: this.search,
          status: this.selectedEntryStatusPix.id,
          page: this.page,
          queryField:this.selectedEntryQueryField.id
        },
      })
        .then((response) => {
          this.desserts = response.data.body.rows as RelatorioPix[];
          this.totalDesserts = Number(response.data.body.count.__total);
          this.stats = response.data.body.stats;
          this.loadingDesserts = false;
          this.loadingData = false;
          if (this.totalDesserts == 0) {
            this.btnDownloadDisabled = true;
          } else {
            this.btnDownloadDisabled = false;
          }
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            Snackbar.show(data.body.error, "error");
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    Comprovante(id: string) {
      Api.get("/v2/public/comprovantes/pix/receive/" + id)
        .then((response) => {
          this.dadosComprovantePixRecebido = response.data.body;
          this.dialogComprovantePixRecebido = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          Snackbar.show(data.body.error, "error");
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    rowClasses(item: any) {
      const ToDate = new Date();
      if (item.status === "PENDENTE") {
        return "";
      }
      if (item.status === "APROVADO") {
        return "success--text";
      }
      if (item.status === "DEVOLVIDO") {
        return "error--text";
      }
      if (item.status === "EXPIRADO") {
        return "error--text";
      }
    },
  },
  watch: {
    '$i18n.locale'() {
      console.log("Locale changed:", this.$i18n.locale);
      this.translateStatusPix();
      this.translateQueryField();
      this.translateHeaders();      
    },
  },
  computed: {
    translatedHeaders() {
      let vm = this as any;
      return vm.headers.map((header: any) => ({
        ...header,
        text: header.text ? String(vm.$t(header.text)) : '',
      }));
    },
  },
});
