
import Vue from "vue";

import moment from "moment";

export default Vue.extend({
  name: "homeRelatorios",

  data() {
    return {
      ModuloInfo: [],
      loadingData: true,

      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
    };
  },
  created() {
    this.isMobile = this.detectMobile();
  },

  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
});
