
import Vue from "vue";

import moment from "moment";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";


interface Breadcrumb {
  text: string;
  disabled: boolean;
  href: string;
}


export default Vue.extend({
  name: "homeAfiliados",

  data() {
    return {
      moduloAtivo: false,
      emv: "",
      alert: {} as Alert,
      loadingData: true,
      btnloading: false,
      btnDisabled: true,
      dialogPixCopyPaste: false,
      isMobile: false,
      lang: navigator.language,
      errors: [],
      moment: moment,
      breadcrumbs: [] as Breadcrumb[], 
      mfaEnabled: true,
      hasInfoSituacaoConta: false,
      situacaoConta: {},
    
    };
  },
  created() {

    this.isMobile = this.detectMobile();
    this.moduloInfo();
    this.updateBreadcrumbs();
  },
  watch: {
    '$i18n.locale': function() {
      this.updateBreadcrumbs();
    }
  },

  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },

    moduloInfo() {
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/client/afiliados/info")
        .then((response) => {
          if (response.data.body.afiQtd > 0) {
            this.moduloAtivo = true
          }

        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.loadingData = false;
          Overlay.hide();
        });
    },
    updateBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t("components.preCadastro.template.form.Home") as string,
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.Affiliates") as string,
          disabled: true,
          href: "/pix",
        },
      ];
    }

  },
});
