
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
export default Vue.extend({
  name: "DetalheCobranca",

  data() {
    return {
      ModuloInfo: [],
      loadingData: true,
      cobrancaData: {
        createdAt: "",
        transactionId: "",
        externalReference: "",
        billingType: "",
        value: "",
        dueDate: "",
        description: "",
        installmentCount: "",
        installmentValue: "0.00",
        installmentNumber: "1",
        paymentDate: null,
        status: "",
        customer: {
          uuid: "",
          name: "",
          taxId: "",
          phone: "",
          email: "",
          zipCode: "",
          street: "",
          number: "",
          complement: "",
          neighborhood: "",
          city: "",
          state: ""
        },
        totalValue: "",
        maxOverdueDate: "",
        discount: {
            type: "",
            value: "0.00",
            dueDateLimitDays: ""
        },
        interest: {
            value: "0.00",
            type: "PERCENTAGE"
        },
        fine: {
            value: "0.00",
            type: "PERCENTAGE"
        },
        nossoNumero: "",
        payNumber: "",
        payBarCode: "",
        pixEmv: "",
        pixEndToEndId: null
      },
      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
      breadcrumbs: [
        {
          text: this.$t('components.preCadastro.template.form.charges'),
          disabled: false,
          href: "/cobrancas",
        },
        {
          text: this.$t('components.preCadastro.template.form.summary'),
          disabled: true,
          href: "/cobrancas/detalhes",
        },
      ],
      showFormEdit: false,
      date: "",
      menuCalend: false,
      activePicker: "YEAR",
    };
  },
  watch: {
    menuCalendar (val: string) {
        return val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    '$i18n.locale': {
      handler() {
        this.updateBreadcrumbs();
      },
      immediate: true,
    },
  },
  created() {
    this.isMobile = this.detectMobile();
    this.moduloInfo();
    this.showEditDueDateNew();
    this.updateBreadcrumbs();
  },

  methods: {
    formataValor(data: number) {
      const valor = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(data);

      return valor.toString().replace("R$", "").trim();
    },
    detectMobile() {
      return screen.width <= 760;
    },
    moduloInfo() {
      const id = this.$route.params.id;
      Overlay.show();
      this.loadingData = true;
      
      Api.get("/v2/client/charges/" + id)
        .then((response) => {
          this.cobrancaData = response.data;
          Overlay.hide();
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.loadingData = false;
        });
    },
    showIconStatusPagto(item: any) {
      if (item.status === "CANCELED" || item.status === "CANCELLED") {
        return "cancelada";
      }
      if (item.status === "ARCHIVED") {
        return "arquivada";
      }
      if (item.status === "PAID") {
        return "paga";
      }
      const ToDate = new Date();
      if (
        !item.paymentDate &&
        new Date(item.dueDate).getTime() > ToDate.getTime()
      ) {
        return "";
      }
      if (item.paymentDate) {
        return "paga";
      }
      if (
        !item.paymentDate &&
        new Date(item.dueDate).getTime() <= ToDate.getTime()
      ) {
        return "vencida";
      }

      if (
        !item.paymentDate &&
        new Date(item.dueDate).getTime() == ToDate.getTime()
      ) {
        return "ativa";
      }
    },
    cancela(item: string) {
      let html = "";
      html += '<div class="text-center pt-7">';
      html +=
        '<svg style="width:64px;height:64px" viewBox="0 0 24 24">    <path fill="#6A1B9A" d="M8.27,3L3,8.27V15.73L8.27,21H15.73C17.5,19.24 21,15.73 21,15.73V8.27L15.73,3M9.1,5H14.9L19,9.1V14.9L14.9,19H9.1L5,14.9V9.1M9.12,7.71L7.71,9.12L10.59,12L7.71,14.88L9.12,16.29L12,13.41L14.88,16.29L16.29,14.88L13.41,12L16.29,9.12L14.88,7.71L12,10.59" /></svg>';
      html += '<h3 class=" mt-5 mb-5">{{ $t("components.preCadastro.template.form.cancelSelectedCharge") }}</h3>';
      html += "<br>";
      html +=
        '<p class="mt-5 mb=5">{{ $t("components.preCadastro.template.form.cancelChargeMessage") }}</p>';
      html += "<br>";
      html += "</div>";
      Swal.fire({
        html: html,

        showCancelButton: true,
        confirmButtonText: "{{ $t('components.preCadastro.template.form.confirm') }}",
        cancelButtonText: "{{ $t('components.preCadastro.template.form.cancel') }}",
      }).then((result) => {
        if (result.isConfirmed) {
          Overlay.show();
          const id = this.$route.params.id;
          Api.delete("/v2/client/charges/" + id)
            .then((response) => {
              Snackbar.show(response.data.body.message);
              this.moduloInfo();
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;
              Snackbar.show(data.body.error, "error");
            })
            .finally(() => {
              Overlay.hide();
            });
        }
      });
    },
    unarchive(item: string) {
      let html = "";
      html += '<div class="text-center">';
      html +=
        '<svg style="width:64px;height:64px" viewBox="0 0 24 24">    <path fill="#6A1B9A" d="M20 21H4V10H6V19H18V10H20V21M3 3H21V9H3V3M5 5V7H19V5M10.5 17V14H8L12 10L16 14H13.5V17" /></svg>';
      html += '<h3 class=" mt-5 mb-5">{{ $t("components.preCadastro.template.form.unarchiveSelectedCharge") }}</h3>';
      html += "<br>";
      html +=
        '<p class="mt-5 mb=5">{{ $t("components.preCadastro.template.form.unarchiveChargeMessage") }}</p>';
      html += "<br>";
      html += "</div>";
      Swal.fire({
        html: html,

        showCancelButton: true,
        confirmButtonText: "{{ $t('components.preCadastro.template.form.confirm') }}",
        cancelButtonText: "{{ $t('components.preCadastro.template.form.cancel') }}",
      }).then((result) => {
        if (result.isConfirmed) {
          Overlay.show();
          Api.get("/v2/client/cobrancas/" + item + "/unarchive")
            .then((response) => {
              Snackbar.show(response.data.body.message);
              this.moduloInfo();
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;
              Snackbar.show(data.body.error, "error");
            })
            .finally(() => {
              Overlay.hide();
            });
        }
      });
    },
    archive(item: string) {
      let html = "";
      html += '<div class="text-center">';
      html +=
        '<svg style="width:64px;height:64px" viewBox="0 0 24 24">    <path fill="#6A1B9A" d="M20 21H4V10H6V19H18V10H20V21M3 3H21V9H3V3M5 5V7H19V5M10.5 11V14H8L12 18L16 14H13.5V11" /></svg>';
      html += '<h3 class=" mt-5 mb-5">{{ $t("components.preCadastro.template.form.archiveSelectedCharge") }}</h3>';
      html += "<br>";
      html +=
        '<p class="mt-5 mb=5">{{ $t("components.preCadastro.template.form.archiveChargeMessage") }}</p>';
      html += "<br>";
      html += "</div>";
      Swal.fire({
        html: html,

        showCancelButton: true,
        confirmButtonText: "{{ $t('components.preCadastro.template.form.confirm') }}",
        cancelButtonText: "{{ $t('components.preCadastro.template.form.cancel') }}",
      }).then((result) => {
        if (result.isConfirmed) {
          Overlay.show();
          Api.get("/v2/client/cobrancas/" + item + "/archive")
            .then((response) => {
              Snackbar.show(response.data.body.message);
              this.moduloInfo();
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;
              Snackbar.show(data.body.error, "error");
            })
            .finally(() => {
              Overlay.hide();
            });
        }
      });
    },
    resendEmail(item: string) {
      let html = "";
      html += '<div class="text-center">';

      html += "<h3>{{ $t('components.preCadastro.template.form.resendByEmail') }}</h3>";
      html +=
        "<p><small>{{ $t('components.preCadastro.template.form.resendEmailMessage') }}</small></p>";
      html += "</div>";
      Swal.fire({
        html: html,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "{{ $t('components.preCadastro.template.form.send') }}",
        cancelButtonText: "{{ $t('components.preCadastro.template.form.doNotSend') }}",
      }).then((result) => {
        if (result.isConfirmed) {
          Overlay.show();
          Api.get("/v2/client/cobrancas/" + item + "/resend")
            .then((response) => {
              Snackbar.show(response.data.body.message);
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;
              Snackbar.show(data.body.error, "error");
            })
            .finally(() => {
              Overlay.hide();
            });
        }
      });
    },
    viewChargeBankslip(transactionId: string) {
      return process.env.VUE_APP_API + "/charges/b/" + transactionId;
    },
    showEditDueDateNew() {
      const confirmEditDuoDate = this.$route.query;
      if(this.$route.query.editCharge){
        this.showFormEdit = true;
      } else {
        this.showFormEdit = false;
      }
    },
    sendPostField(transactionId: string) {
      if (this.date !== "") {
        Overlay.show();
        let dateDue = this.date.split("-");
        const dateTraits = dateDue[2] + "." + dateDue[1] + "." + dateDue[0];
        console.log(dateTraits);
        Api.put("/v2/client/charges/" + transactionId, { "novaDataVencimento": dateTraits })
          .then((response) => {
            Snackbar.show("{{ $t('components.preCadastro.template.form.dueDateUpdated') }}", "success");
          })
          .catch((error: any) => {
            const response = error.response as AxiosResponse;
            const data = response.data;
            Snackbar.show(data.message, "error");
          })
          .finally(() => {
            Overlay.hide();
          });
      } else {
        Snackbar.show("{{ $t('components.preCadastro.template.form.fillDueDate') }}", "error");
      }
    },
    updateBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t('components.preCadastro.template.form.Charges'),
          disabled: false,
          href: "/cobrancas",
        },
        {
          text: this.$t('components.preCadastro.template.form.Summary'),
          disabled: true,
          href: "/cobrancas/detalhes",
        },
      ];
    }
  },
});
