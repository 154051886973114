import store from "@/store";

export class Snackbar {
  static show(message: string, type = "success"): void {
    store.dispatch("showSnackbar", {
      type,
      message,
    });
  }
}
