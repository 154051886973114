
import Vue from "vue";
import Api from "@/services/Api";
import Auth from "@/lib/Auth";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import VueClipboard from "vue-clipboard2";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import moment from "moment";
import { DepositoPix } from "@/models/DepositoPix";
import { VMoney } from "v-money";

import router from "@/router";

Vue.use(VueClipboard);

export default Vue.extend({
  name: "AdicionarSaldoPix",

  data() {
    return {
      progressTempoCkPix: 0,
      progressCkPixShow: true,
      progressQueryPix: false,
      progressPixCkinterval: 0,
      showTxtCkPix: false,
      pinCode: "",
      dialogPix: false,
      dialogPIN: false,
      form: {} as DepositoPix,
      errors: [],
      valid: true,
      btnLoading: false,
      alert: {} as Alert,
      panelAtividadeRecentes: 0,
      panelModuloInfo: 0,
      isMobile: false,
      dialogInformarDeposito: false,
      ModuloInfo: [],
      Tarifas: [],
      ContasDisponivels: [],
      AtividadeRecente: [],
      PixData: [],
      loadingData: true,
      lang: navigator.language,
      idBoletoCancela: 0,
      moment: moment,
      dadosUsuario: {},
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      idPixChecking: "",
      ckStatusPagto: false,
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.moduloInfo();
    this.dadosUsuario = Auth.getUserData();
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.updateTranslations();
      },
      immediate: true
    }
  },
  methods: {
    closeDialogPix() {
      this.form.valor = "0";
      this.dialogPix = false;
      this.ckStatusPagto = false;
    },

    ckStatusAlert(status: string) {
      if (status === "PENDENTE") {
        return "warning";
      }
      if (status === "CANCELADO") {
        return "error";
      }

      if (status === "APROVADO") {
        return "success";
      }
    },

    ckIconStatus(status: string) {
      if (status === "PENDENTE") {
        return "mdi mdi-clock-outline";
      }
      if (status === "CANCELADO") {
        return "mdi mdi-block-helper";
      }
      if (status === "APROVADO") {
        return "mdi mdi-check-circle-outline";
      }
    },
    ckStatusChip(status: string) {
      if (status === "PENDENTE") {
        return "orange";
      }
      if (status === "CANCELADO") {
        return "red";
      }
      if (status === "APROVADO") {
        return "green";
      }
    },

    translateStatus(status: string) {
      if (status === "PENDENTE") {
        return this.$t("components.preCadastro.template.form.Pending");
      }
      if (status === "CANCELADO") {
        return this.$t("components.preCadastro.template.form.Canceled");
      }
      if (status === "APROVADO") {
        return this.$t("components.preCadastro.template.form.Approved");
      }
    },

    updateTranslations() {
      this.AtividadeRecente.forEach((rec: any) => {
        rec.statusTranslated = this.translateStatus(rec.status);
      });
    },

    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    checkStatusPagamento() {
      Overlay.show();
      Api.get(
        "/v2/client/financeiro/adicionar/pix/status/" + this.idPixChecking
      )
        .then((response) => {
          if (response.data.body.status === "APROVADO") {
            this.ckStatusPagto = false;
            router.push("/movimento");
          }
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
        })
        .finally(() => {
          this.btnLoading = false;
          Overlay.hide();
        });
    },
    visualizarQrPix(id: string) {
      Overlay.show();
      Api.post("/v2/client/financeiro/adicionar/pix/detalhe/" + id)
        .then((response) => {
          this.PixData = response.data.body;
          this.dialogPix = true;
          this.idPixChecking = response.data.body.controle;
          this.ckStatusPagto = true;
          this.queryAndIndeterminate();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
        })
        .finally(() => {
          this.btnLoading = false;
          Overlay.hide();
        });
    },

    moduloInfo() {
      const id = this.$route.params.id;
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/client/financeiro/adicionar/pix/info")
        .then((response) => {
          this.ModuloInfo = response.data.body.moduloInfo;
          this.AtividadeRecente = response.data.body.pixRecentes;
          this.updateTranslations();
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            Snackbar.show(data.body.message, "error");
          }
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    gerarPix() {
      if (this.form.valor.length < 3) {
        Swal.fire({
          title: this.$t("components.preCadastro.template.form.EnterValue"),
          icon: "error"
        });
        return false;
      }
      Swal.fire({
        title: String(this.$t("components.preCadastro.template.form.Confirmation")),
        text: String(this.$t("components.preCadastro.template.form.ConfirmGeneration")),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: String(this.$t("components.preCadastro.template.form.Yes")),
        cancelButtonText: String(this.$t("components.preCadastro.template.form.No"))
      }).then((result) => {
        if (result.isConfirmed) {
          this.errors = [];
          this.btnLoading = true;
          Overlay.show();
          Api.post("/v2/client/financeiro/adicionar/pix/emitir", this.form)
            .then((response) => {
              this.PixData = response.data.body;
              this.dialogPix = true;
              this.moduloInfo();
              this.idPixChecking = response.data.body.id;
              this.ckStatusPagto = true;
              this.queryAndIndeterminate();
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;

              Swal.fire({
                text: data.body.error,
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
            })
            .finally(() => {
              this.btnLoading = false;
              Overlay.hide();
            });
        }
      });
    },
    doCopy(str: string) {
  const container = document.querySelector(".v-dialog") as HTMLElement;
      this.$copyText(str, container).then(() => {
        Snackbar.show(this.$t("components.preCadastro.template.form.CopiedSuccessfully") as string);
      });
    },

    onCopy() {
      Snackbar.show(this.$t("components.preCadastro.template.form.CopiedSuccessfully") as string);
    },

    onCopyError() {
      Snackbar.show(this.$t("components.preCadastro.template.form.CopyError") as string, "error");
    },
    showAlert(show = false, message = "", type = "error"): void {
      this.alert = {
        show,
        type,
        message,
      };
    },
    queryAndIndeterminate() {
      if (this.ckStatusPagto) {
        this.progressQueryPix = true;
        this.progressCkPixShow = true;
        this.progressTempoCkPix = 0;

        setTimeout(() => {
          this.progressQueryPix = false;

          this.progressPixCkinterval = setInterval(() => {
            if (this.progressTempoCkPix === 100) {
              if (this.ckStatusPagto) {
                this.checkStatusPagamento();
              }
              clearInterval(this.progressPixCkinterval);
              this.progressCkPixShow = false;

              return setTimeout(this.queryAndIndeterminate, 1000);
            }
            this.progressTempoCkPix += 10;
          }, 1000);
        }, 1000);
      }
    },
  },
  directives: { money: VMoney },
});
