
import Vue from "vue";
import Api from "@/services/Api";
import { Overlay } from "@/lib/Overlay";
import { AxiosResponse } from "axios";
import { VMoney } from "v-money";
import moment from "moment";
import { TableOptions } from "@/models/TableOptions";
import { SwapQuote } from "@/models/SwapQuote";
import Swal from "sweetalert2";
import jwtDecode from "jwt-decode";



export interface Swaps {
  id: string;
  swap: any;
}

interface AmountIn {
  amountIn: string;
  amountInFormatado: string;
  amountInUSD: string;
  amountInEUR: string;
}

export default Vue.extend({
  name: "NewSwap",

  data() {
    return {
      loadingBalance: false,
      saldoDisponivel: "0,00",
      showSaldo: false,
      transactionPin: "",
      btnloading: false,
      OkCreateSwap: false,
      dialogConfirmSwap: false,
      QuoteRespJWT: [],
      showAmountToPayUSDT: true,
      showNetInverseRateAmountOut: false,
      netInverseRateAmountOut: "R$ 1 = 0,000000 USDT",
      netRateAmountOut: "1 USDT = R$ 0,000000",
      qrCodeGenerated: false,
      amountIn: {
        amountIn: "0.0000",
        amountInFormatado: "0,00",
        amountInUSD: "0,00",
        amountInEUR: "0,00",
      } as AmountIn,
      ticketPrice: "1 USDT = R$ 0,000000",
      netAmountOut: {
        netAmountOut: "0.000000",
        netAmountOutFormatado: "0,00",
        netAmountOutEURFormatado: "0,00",
      },
      dialogSwap: false,
      serviceTax: "0,00",
      serviceTaxPerc: "0",

      withdrawTax: "0,00",
      blockchainTax: "0",
      addressOut: "",
      addressOutPlaceholder: "0x48d17...",
      defaultBtnCurrencyIn: {
        icon: require("@/assets/images/brl.svg"),
        namePt: "Real",
        sigla: "BRL",
      },
      defaultBtnCurrencyOut: {
        icon: require("@/assets/images/usdt.svg"),
        namePt: "Tether",
        sigla: "USDT",
      },
      defaultBtnNetWorkOut: {
        icon: require("@/assets/images/eth.svg"),
        namePt: "Ethereum (ERC20)",
      },
      rules: {
        required: (value: string) => !!value || "Required.",
      },
      errors: [] as any,
      swapExactFiatForCrypto: false,
      swapFiatForExactCrypto: false,
      formQuote: {} as SwapQuote,

      loadingDesserts: true,
      totalDesserts: 0,
      options: {} as TableOptions,
      search: "",
      moment: moment,
      lang: navigator.language,
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      timeout: 0,
      markets: [], //JSON.parse(localStorage.getItem("SwapMarkets")),
      isMobile: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      moneyBTC: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 6,
        masked: false /* doesn't work with directive */,
      },

      currencies: [],
      marketsBTC: [] as any,
      marketsUSDT: [] as any,
      defaultMarket: [] as any,
      user: "",
      amountInBrl: "",
      netAmountOutBTC: "",
      okToQuote: true,
      countDown: 20,
      countDownCount: 0,
      countDownStart: true,
    };
  },
  created() {
    this.isMobile = this.detectMobile();

    this.getCurrencies();
    this.formQuote.currencyIn = "BRL";
    this.formQuote.currencyOut = "USDT";
    this.formQuote.networkOut = "eth-mainnet";
    this.formQuote.netAmountOut = this.netAmountOut.netAmountOut;
    // this.countDownTimer();
    this.countDownStart = false;
  },

  methods: {
    openDialoQuote() {
      this.resetFieldsQuote();
      this.getBalanceSwap();

      this.dialogSwap = true;
      this.countDownStart = false;
    },
    resetFieldsQuote() {
      if (!this.qrCodeGenerated) {
        this.amountIn = {
            amountIn: "0.0000",
            amountInFormatado: "0,00",
            amountInUSD: "0,00",
            amountInEUR: "0,00",
        };
    }
      this.dialogConfirmSwap = false;
      this.OkCreateSwap = false;
      this.btnloading = false;
  
    // Somente limpar amountIn se o QR code não foi gerado
    if (!this.qrCodeGenerated) {
        this.amountIn = { amountIn: "0.0000", amountInFormatado: "0,00", amountInUSD: "0,00", amountInEUR: "0,00" };
    }
      this.netAmountOutBTC = "";
      let inputNetAmountOut =
        (document.getElementById("BTC") as HTMLInputElement) || {};
      inputNetAmountOut.value = "0";
      let inputAmountIn =
        (document.getElementById("BRL") as HTMLInputElement) || {};
      inputAmountIn.value = "0";
      this.formQuote.currencyIn = "BRL";
      this.formQuote.currencyOut = "USDT";
      this.formQuote.networkOut = "eth-mainnet";
      this.formQuote.amountIn = "";
      this.formQuote.netAmountOut = "";

      this.netInverseRateAmountOut = "R$ 1 = 0,000000 USDT";
      this.netRateAmountOut = "1 USDT = R$ 0,000000";
      (this.amountIn = {
        amountIn: "0.0000",
        amountInFormatado: "0,00",
        amountInUSD: "0,00",
        amountInEUR: "0,00",
      }),
        (this.ticketPrice = "1 USDT = R$ 0,000000");
      this.netAmountOut = {
        netAmountOut: "0.00000000",
        netAmountOutFormatado: "0,00",
        netAmountOutEURFormatado: "0,00",
      };
      this.transactionPin = "";
      this.QuoteRespJWT = [];
      this.serviceTax = "0,00";
      this.serviceTaxPerc = "0";
      this.withdrawTax = "0,00";
      this.blockchainTax = "0";
      this.addressOut = "";
      this.countDownStart = false;
      this.countDownCount = 0;
    },
    openDialogConfirmSwap() {
      if (this.addressOut.length < 10) {
        this.errors["addressOut"] = "Carteira inválida";
        return false;
      }
      this.dialogConfirmSwap = true;
      //  this.dialogSwap = false;
    },
    cancelConfirmSwap() {
      this.resetFieldsQuote();
      this.transactionPin = "";
      this.dialogConfirmSwap = false;
      this.dialogSwap = true;
    },
    cancelSwap() {
      this.resetFieldsQuote();
      this.OkCreateSwap = false;
      this.dialogSwap = false;
    },
    createSwap() {
      this.btnloading = true;

      this.errors = [];

      if (this.transactionPin.length < 6) {
        this.errors["transactionPin"] = "Pin de transações inválido";
        this.btnloading = false;
        return false;
      }
      this.OkCreateSwap = false;
      this.countDownStart = false;

      Overlay.show();
      this.btnloading = true;
      this.dialogConfirmSwap = false;
      Api.post("/v2/client/cripto/swaps/swap", {
        transactionPin: this.transactionPin,
        addressOut: this.addressOut,
        userId: sessionStorage.getItem("userCriptoId"),
        quoteData: this.QuoteRespJWT,
        amountIn: this.amountIn.amountIn, 
      })
        .then((response) => {
          this.qrCodeGenerated = true; 
          this.dialogConfirmSwap = false;
          this.dialogSwap = false;
          this.addressOut = "";
          Swal.fire({
            title: this.$t("components.preCadastro.template.form.ConversionCreatedSuccessfully") as string,
            text: this.$t("components.preCadastro.template.form.PleaseWaitProcessing") as string,
            icon: "success",
          });
          this.$emit("callmethod", 0);
          this.resetFieldsQuote();
          this.countDownStart = false;
          this.dialogSwap = false;
          this.dialogConfirmSwap = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            Swal.fire({
              title: "Opps",
              text: data.body.error,
              icon: "error",
            });
          }
          this.dialogConfirmSwap = false;
          this.resetFieldsQuote();
          this.OkCreateSwap = false;
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.btnloading = false;
          this.OkCreateSwap = true;
          this.okToQuote = true;
          this.dialogConfirmSwap = false;
        });
    },
    //crypto para real
    fnSwapFiatForExactCrypto() {
      this.errors = [];

      //console.log("swapFiatForExactCrypto");
      this.swapExactFiatForCrypto = false;
      this.swapFiatForExactCrypto = true;

      this.formQuote.type = "swapFiatForExactCrypto";
      //this.getQuote();
      //this.formQuote.amountIn = "";
    },
    //real para crypto
    fnSwapExactFiatForCrypto() {
      this.errors = [];

      // console.log("swapExactFiatForCrypto");
      this.swapExactFiatForCrypto = true;
      this.swapFiatForExactCrypto = false;
      this.formQuote.type = "swapExactFiatForCrypto";
      // this.formQuote.netAmountOut = "";
      //console.log(this.formQuote.amountIn);
      //console.log(this.formQuote.amountIn.length);

      // this.getQuote();
    },
    makeBtnCurrencIn(icon: string, namePt: string, sigla: string) {
      this.defaultBtnCurrencyIn.icon = require("@/assets/images/" +
        icon.toLocaleLowerCase() +
        ".svg");
      this.defaultBtnCurrencyIn.namePt = namePt;
      this.defaultBtnCurrencyIn.sigla = sigla;

      this.formQuote.currencyIn = sigla;
    },
    makeBtnCurrencyOut(item: any) {
  this.defaultBtnCurrencyOut.icon = require("@/assets/images/" +
    item.currency.toLocaleLowerCase() +
    ".svg");
  this.defaultBtnCurrencyOut.namePt = item.name;
  this.defaultBtnCurrencyOut.sigla = item.currency;

  // Aqui seleciona a rede correta associada à moeda selecionada
  if (item.deposit && item.deposit.networks.length > 0) {
    this.defaultMarket = item.deposit.networks; // Redes disponíveis para essa moeda
    this.formQuote.networkOut = this.defaultMarket[0].nameId; // Rede padrão
  }

  // Atualiza a moeda de saída no formQuote
  this.formQuote.currencyOut = item.currency;

  // Aqui garantimos que o quote seja recalculado com os novos valores
  this.getQuote();
}
,
    makeBtnNetworkOut(item: any) {
      this.addressOut = "";
      this.addressOutPlaceholder = item.placeholder;

      this.defaultBtnNetWorkOut.icon = require("@/assets/images/" +
        item.currencySymbol.toLocaleLowerCase() +
        ".svg");
      this.defaultBtnNetWorkOut.namePt = item.description;

      this.formQuote.networkOut = item.nameId;

      // this.getQuote();
    },

    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    getMarkets() {
      this.loadingDesserts = true;
      Overlay.show();
      Api.get("/v2/client/cripto/swaps/markets")
        .then((response) => {
          this.markets = response.data.data;

          const markets = response.data.data;
          for (let index = 0; index < markets.length; index++) {
            const element = markets[index];

            if (element.baseCurrency.currency === "USDT") {
              this.marketsUSDT = markets[index].baseCurrency.withdraw
                .networks as any;
              this.defaultMarket = this.marketsUSDT;
            }
            if (element.baseCurrency.currency === "BTC") {
              this.marketsBTC = markets[index].baseCurrency.withdraw.networks;
            }
          }
        })
        .finally(() => {
          Overlay.hide();
          this.loadingDesserts = false;
          this.dialogSwap = false;
        });
    },
    getCurrencies() {
      this.loadingDesserts = true;
      Overlay.show();
      Api.get("/v2/client/cripto/swaps/currencies")
        .then((response) => {
          this.currencies = response.data.data;

          localStorage.removeItem("SwapCurrencies");
          localStorage.setItem(
            "SwapCurrencies",
            JSON.stringify(response.data.data)
          );
        })
        .finally(() => {
          this.getMarkets();
          Overlay.hide();
          this.loadingDesserts = false;
        });
    },

    removeFormatacaoMoeda($str: string) {
      return $str.replace(/\D/g, "");
    },
    validaAmountIn() {
      const valorAmountIn = this.removeFormatacaoMoeda(this.amountInBrl);

      if (
        valorAmountIn === "000" ||
        valorAmountIn === "0" ||
        valorAmountIn === "00" ||
        valorAmountIn === ""
      ) {
        return false;
      }
      return true;
    },
    validaAmountOut() {
      const valorNetAmountOut = this.removeFormatacaoMoeda(
        this.netAmountOutBTC
      );
      if (
        valorNetAmountOut === "000000000" ||
        valorNetAmountOut === "0000000" ||
        valorNetAmountOut === "000" ||
        valorNetAmountOut === "0" ||
        valorNetAmountOut === "00" ||
        valorNetAmountOut === ""
      ) {
        return false;
      }
      return true;
    },
    countDownTimer() {
      if (this.countDownStart && this.countDownCount <= 5) {
        setTimeout(() => {
          if (this.countDown > 0 && this.countDownStart) {
            this.countDown -= 1;
            this.countDownTimer();
          }

          //console.log(this.countDown);
        }, 1000);

        if (this.countDown == 0) {
          this.countDownCount += 1;
          this.countDown = 20;
          // console.log("getQuote(" + this.countDownCount + ")");
          this.countDownStart = false;
          this.getQuote();
        }
        if (this.countDownCount == 5) {
          // console.log("finalizou");
          this.resetFieldsQuote();
          this.countDownStart = false;
        }
        // console.log(this.countDownStart);
      }
    },
    getQuote() {
      if (this.qrCodeGenerated) {
        console.log("QR code já gerado, evitando nova requisição.");
        return;
      }
      this.errors = [];
      this.QuoteRespJWT = [];
      this.btnloading = true;
      this.OkCreateSwap = false;

      setTimeout(() => {
        let postParams: any = {}; // Define o tipo correto de postParams

        if (this.swapExactFiatForCrypto) {
          if (!this.validaAmountIn() && !this.amountInBrl) {
            return false;
          }

          // Verifique se this.amountIn está corretamente inicializado antes de acessar suas propriedades
          if (!this.amountIn) {
            this.amountIn = {
              amountIn: "0.0000",
              amountInFormatado: "0,00",
              amountInUSD: "0,00",
              amountInEUR: "0,00",
            };
          }

          postParams = {
            amountIn: this.amountIn.amountIn || this.amountInBrl
              .replace("R$", "")
              .replace(" ", "")
              .trim(),
            currencyIn: this.formQuote.currencyIn,
            currencyOut: this.formQuote.currencyOut,
            networkOut: this.formQuote.networkOut,
            user: sessionStorage.getItem("userCriptoId"),
            type: this.formQuote.type,
          };

          if (!postParams.amountIn) {
            console.error("AmountIn está vazio");
            return; // Evita fazer a requisição se amountIn estiver vazio
          }
        } else {
          if (!this.validaAmountOut()) {
            return false;
          }

          postParams = {
            netAmountOut: this.netAmountOutBTC,
            currencyIn: this.formQuote.currencyIn,
            currencyOut: this.formQuote.currencyOut,
            networkOut: this.formQuote.networkOut,
            user: sessionStorage.getItem("userCriptoId"),
            type: this.formQuote.type,
          };
        }

        this.loadingDesserts = true;
        this.okToQuote = false;

        Api.get("/v2/client/cripto/swaps/quote-brl", { params: postParams })
          .then((response) => {
            const dataResp = jwtDecode(response.data.body.quote) as any;
            this.QuoteRespJWT = response.data.body.quote;

            if (dataResp.data.status === "ok") {
              this.amountIn = dataResp.data.data.quote.amountIn || this.amountIn; // Verifique se o valor existe
              this.qrCodeGenerated = true;
            }
          })
          .catch((error) => {
            console.log("Erro completo:", error.response); // Isso ajudará a inspecionar o objeto de erro completo
            let errorMessage = error?.response?.data?.body?.error || error?.response?.data?.error || "Erro desconhecido";

            if (errorMessage.includes("Amount_Negative_Error_50")) {
              errorMessage = "Você digitou um valor abaixo do mínimo.";
            }

            Swal.fire({
              title: "Erro",
              text: errorMessage,
              icon: "error",
            });
          })
          .finally(() => {
            this.loadingDesserts = false;
            this.btnloading = false;
            setTimeout(() => {
              this.okToQuote = true;
            }, 3000);
          });
      }, 500);
}
,
    getCurrencySymbol(symbol: string) {
      return require(`@/assets/images/${symbol.toLocaleLowerCase()}.svg`);
    },
    getBalanceSwap() {
      this.loadingBalance = true;

      Api.get("/v2/client/cripto/swaps/balance")
        .then((response) => {
          this.saldoDisponivel = response.data.body.swapBalanceFormatado;
        })
        .finally(() => {
          this.loadingBalance = false;
        });
    },
    doDelayedQuote() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.getQuote();
        this.okToQuote = true;
      }, 500);
    },
  },
  watch: {
    amountInBrl: {
      handler() {
        if (!this.validaAmountIn()) {
          return false;
        }
        if (this.okToQuote) {
          this.fnSwapExactFiatForCrypto();
          this.doDelayedQuote();
        }
      },
    },
    netAmountOutBTC: {
      handler() {
        if (!this.validaAmountOut()) {
          return false;
        }
        if (this.okToQuote) {
          this.fnSwapFiatForExactCrypto();
          this.doDelayedQuote();
        }
      },
    },
  },
  directives: { money: VMoney, moneyBTC: VMoney },
});
