
import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { RedefinirSenhaForgot } from "@/models/RedefinirSenhaForgot";
import Axios, { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import { Overlay } from "@/lib/Overlay";
import router from "@/router";
import VueRecaptcha from "vue-recaptcha";
import LocaleSwitcher from "../../components/LocaleSwitcher.vue";

export default Vue.extend({
  name: "redefinirSenha",
  data: () => ({
    showpass: false,
    emailRules: [
      (v: any) => !!v || "Informe seu e-mail",
      (v: any) => /.+@.+\..+/.test(v) || "Informe um e-mail válido",
    ],

    valid: true,
    hasSent: false,
    msgHasSent: "Senha alterada com sucesso!",
    form: {} as RedefinirSenhaForgot,
    errors: [] as any,
    btnLoading: false,
    lembraLogin: false,
    clientIp: "0.0.0.0",
    sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  }),
  components: {
    "vue-recaptcha": VueRecaptcha,
    LocaleSwitcher,
  },

  methods: {
    validate() {
      const wrapper = this.$refs.formAddConta as HTMLFormElement;
      wrapper?.validate();
    },
    redefinirSenha(token: string) {
      this.form.token = this.$route.params.token;
      this.errors = [];
      this.btnLoading = true;
      Overlay.show();
      Api.post("/v2/client/changePassword", this.form, {
        headers: {
          recaptcha: token,
        },
      })
        .then((response) => {
          this.msgHasSent = response.data.body.message;

          this.hasSent = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.btnLoading = false;
          //  console.log("finalizou");

          Overlay.hide();
          this.resetRecaptcha();
        });
    },
    onSubmit: function () {
      (this.$refs.invisibleRecaptcha as HTMLFormElement).execute();
    },
    onVerify: function (response: string) {
      this.redefinirSenha(response);
    },
    onExpired: function () {
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      (this.$refs.invisibleRecaptcha as HTMLFormElement).reset(); // Direct call reset method
    },
  },
});
