
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
Vue.use(VueApexCharts);

export default Vue.extend({
  name: "GraficoGGR",

  data() {
    return {
      moment: moment,
      chartOptions: {
        series: [
          {
            name: "entradas",
            data: [],
          },
        ],

        chart: {
          height: 350,
          type: "bar",
          toolbar: { show: false },
        },

        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        fill: {
          opacity: 1,
        },
        xaxis: { categories: ["17/1"] },
        tooltip: {
          x: {
            format: "dd/MM/yyyy",
          },
        },
      },
      hasGrafico: false,
      loadingDesserts: false,
    };
  },
  components: {
    apexchart: VueApexCharts,
  },
  created() {
    this.loadDatas();
  },

  methods: {
    loadDatas() {
      this.loadingDesserts = true;
      Api.get("/v2/client/ggr/grafico")
        .then((response) => {
          //this.desserts = response.data.body.rows as GraficoGGRTable[];
          //this.chartOptions.series[0].data = response.data.body.values;
          this.chartOptions.series = [
            {
              name: "Total",
              data: response.data.body.values,
            },
          ];
          this.$nextTick(() => {
            this.chartOptions.xaxis = {
              categories: response.data.body.xaxis,
            };
            this.hasGrafico = true;
          });

          console.log(this.chartOptions.xaxis);
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;

          if (status != 200) {
            // Snackbar.show(response.data.body.error, "error");
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          // functin
        });
    },
  },
});
