import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import pt from "vuetify/src/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#11205F",
        secondary: "#7B1FA2",
      },
      dark: {
        secondary: "#7B1FA2",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
