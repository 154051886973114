
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";

export default Vue.extend({
  name: "ComprovanteTransferencia",

  data() {
    return {
      dadosComprovanteTransferencia: [],
      loadingData: true,
      lang: navigator.language,
      alert: {} as Alert,
      moment: moment,
    };
  },
  created() {
    this.loadDatas();
  },

  methods: {
    loadDatas() {
      const id = this.$route.params.id;
      Overlay.show();
      this.showAlert();
      this.loadingData = true;
      Api.get("/v2/public/comprovantes/transferencias/" + id)
        .then((response) => {
          this.dadosComprovanteTransferencia = response.data.body;
          this.loadingData = false;
          setTimeout(() => {
            window.print();
          }, 2000);
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            this.showAlert(true, data.body.error);
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },

    showAlert(show = false, message = "", type = "error"): void {
      this.alert = {
        show,
        type,
        message,
      };
    },
  },
});
