
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import router from "@/router";

export default Vue.extend({
  name: "EncerrarConta",

  data() {
    return {
      dialogTermos: false,
      ModuloInfo: [],
      loadingData: true,
      alert: {} as Alert,
      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
      hasData: false,
      token: "",
      motivos: [
        { id: 0, motivo: "Selecione" },
        { id: 1, motivo: "Não gostei das Tarifas" },
        { id: 2, motivo: "Não gostei do atendimento" },
        { id: 3, motivo: "Tem poucos serviços ainda" },
        { id: 4, motivo: "Apenas não quero usar mais" },
        { id: 5, motivo: "Prefiro não comentar" },
      ],
      motivoCancelamento: { id: 0, motivo: "Selecione" },
      ckTermos: false,
      termoEncerramento: "",
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.moduloInfo();
  },

  methods: {
    encerrarConta() {
      this.dialogTermos = false;
      Swal.fire({
        icon: "question",
        title: "Confirmação",
        text: "Deseja realmente cancelar sua conta EzzeBank?",
        showCancelButton: true,
        confirmButtonText: "Sim, cancelar",
        cancelButtonText: "Agora não",
      }).then((result) => {
        if (result.isConfirmed) {
          this.errors = [];

          Overlay.show();
          Api.post("/v2/client/account/encerramento/confirma", {
            motivo: this.motivoCancelamento.id,
            token: this.token,
          })
            .then((response) => {
              Swal.fire({
                icon: "success",
                title: response.data.body.message,

                showCancelButton: false,
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.isConfirmed) {
                  router.push("/login");
                }
              });
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;

              Swal.fire({
                text: data.body.error,
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
            })
            .finally(() => {
              Overlay.hide();
            });
        }
      });
    },
    openDialogTermos() {
      if (this.motivoCancelamento.id == 0) {
        Snackbar.show("Selecione o motivo", "error");
        return false;
      }
      this.dialogTermos = true;
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    moduloInfo() {
      const id = this.$route.params.id;
      Overlay.show();
      this.showAlert();
      this.loadingData = true;
      Api.get("/v2/client/account/encerramento/validatoken/" + id)
        .then((response) => {
          this.motivos = response.data.body.motivos;
          this.termoEncerramento = response.data.body.termos;
          this.hasData = true;
          this.token = id;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const data = response.data;

          this.showAlert(true, data.body.error);
        })
        .finally(() => {
          this.loadingData = false;
          Overlay.hide();
        });
    },
    showAlert(show = false, message = "", type = "error"): void {
      this.alert = {
        show,
        type,
        message,
      };
    },
  },
});
