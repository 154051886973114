
import Vue from "vue";
import Api from "@/services/Api";
import router from "@/router";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import VueClipboard from "vue-clipboard2";

import { Alert } from "@/lib/Alert";

Vue.use(VueClipboard);
export default Vue.extend({
  name: "PagarPIX",

  data() {
    return {
      PixData: [],
      loadingData: true,
      alert: {} as Alert,
      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.moduloInfo();
  },

  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    moduloInfo() {
      const id = this.$route.params.id;
      this.showAlert();
      Overlay.show();
      Api.get("/v2/client/financeiro/adicionar/pix/detalhe/" + id)
        .then((response) => {
          //
          this.PixData = response.data.body;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          this.showAlert(true, data.body.error);
        })
        .finally(() => {
          Overlay.hide();
          this.loadingData = false;
        });
    },
    doCopy(str: string) {
      this.$copyText(str).then(function (e) {
        Snackbar.show("Copiado com sucesso!");
      });
    },

    onCopy() {
      // console.log(str);

      Snackbar.show("Copiado com sucesso!");
    },
    onCopyError() {
      // console.log(str);

      Snackbar.show("Erro ao copiar para área de transferência", "error");
    },
    showAlert(show = false, message = "", type = "error"): void {
      this.alert = {
        show,
        type,
        message,
      };
    },
  },
});
