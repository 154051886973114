
import Vue from "vue";
import ApiMobileService from "@/services/ApiMobile";
import { defineComponent } from "@vue/composition-api";
import { AxiosError } from "axios";
import { getMessaging, getToken, Messaging } from "firebase/messaging";
import Auth from "@/lib/Auth";
import { Snackbar } from "@/lib/Snackbar";
import { Overlay } from "@/lib/Overlay";
import browserDetect from "vue-browser-detect-plugin";

Vue.use(browserDetect);

interface ErrorResponse {
  message: string | null;
}
export default Vue.extend({
  name: "Dashboard",

  data: () => ({
    dialogNotifications: false,
    messaging: {} as Messaging,
  }),
  created() {
    // const navegador = browserDetectisIE();
    // console.log(this.$browserDetect);
    if (
      localStorage.push_enabled == undefined ||
      localStorage.push_enabled == null
    ) {
      if (
        sessionStorage.push_ignore == undefined ||
        sessionStorage.push_ignore == null
      ) {
        // console.log(sessionStorage.push_ignore);

        //nao exibir para chromeIOS ou IOS ou IE... e esse tal de Brave que nunca nem vi
        if (this.ckDevice()) {
          this.setup();
        }
      }
    }
  },
  methods: {
    ckDevice() {
      let retorno = true;
      if (this.$browserDetect.isIOS) {
        retorno = false;
      }
      if (this.$browserDetect.isIE) {
        retorno = false;
      }
      if (this.$browserDetect.isBrave) {
        retorno = false;
      }
      if (this.$browserDetect.isOpera) {
        retorno = false;
      }
      // alert(retorno);
      return retorno;
    },
    ignorePush() {
      sessionStorage.setItem("push_ignore", "true");
      this.dialogNotifications = false;
    },
    setup() {
      this.messaging = getMessaging();
      //this.messaging = messaging;
      // this.dialogNotifications = true;
    },
    acceptPush() {
      Overlay.show();
      this.dialogNotifications = false;
      getToken(this.messaging, {
        vapidKey:
          "BEvBhJjcNyMLs60dw9w3mIKx6O16Mmt8p5KXzB-dwgud1U0iAg3y16gLZYB_mmVLV227n63uOq1iRIjFORN-0ys",
      }) // Essa chave é pública, pode ficar aí de boa!
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            ApiMobileService.post("v1/notification/device", {
              token: currentToken,
            })
              .then((response) => {
                localStorage.setItem("push_enabled", response.data._id);
                Snackbar.show("Agora você irá receber as notificações");
              })
              .catch((e) => {
                const error = e as AxiosError<ErrorResponse>;
                const status = error.response?.status;

                if (status == 401) {
                  // Auth.logout();
                }
              });

            // ...
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        })
        .finally(() => {
          Overlay.hide();
        });
    },
  },
});
