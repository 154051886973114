
import Vue from "vue";
import Swal from "sweetalert2";
export default Vue.extend({
  name: "homeMenuCripto",
  data() {
    return {
      mfaEnabled: true,
      hasInfoSituacaoConta: false,
      situacaoConta: {},
      loadedData: false,
      menuCriptoItens: [
        { title: "Conversões", subtitle: "", icon: "mdi mdi-swap-horizontal" },
      ],
    };
  },
  methods: {
    CommingSon() {
      Swal.fire(
        this.$t("components.common.swals.CommingSonTitle") as string,
        this.$t("components.common.messages.CommingSonMessage") as string,
        "info"
      );
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("components.appBar.template.home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.appBar.template.cripto"),
          disabled: true,
          href: "/cripto",
        },
      ];
    },
  },
});
