
import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";

import Swal from "sweetalert2";
import { VMoney } from "v-money";
export default Vue.extend({
  name: "PagamentoContas",

  data() {
    return {
      stepper: 1,
      stepPagto: 1,
      dialogComprovanteBoleto: false,
      dialogRequestPinPagtoBoleto: false,
      dialogAlteraValorBoleto: false,
      dialogComprovantePagamento: false,
      dialogPIN: false,
      pinCode: "",
      stepConfirmValidadeBoleto: false,
      codigoValidacaoPagto: "",
      ModuloInfo: [],
      loadingData: true,
      codigoBarras: "",
      descricaoPagto: "",
      lang: navigator.language,
      errors: [],
      moment: moment,
      permiteAlterarValor: false,
      permiteAlterarData: false,
      exibeJuros: false,
      exibeMulta: false,
      exibeDescontos: false,
      valorPagamento: 0,
      valorPagamentoAlterado: 0,
      dadosBoleto: {} as any,
      btnLoading: false,
      telefone: "",
      dadosPagador: {} as any,
      pagamentosRecentes: [],
      isMobile: false,
      saldoCliente: 0,
      pagtoToken: "",
      requestCodeSMSBoleto: false,
      money: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      breadcrumbs: [
        {
          text: "Serviços",
          disabled: false,
          href: "/servicos",
        },
        {
          text: "Pagamentos de contas",
          disabled: true,
          href: "/pagamentos",
        },
      ],

      headerPagamentos: [
        { text: "Beneficiário", value: "NomeBeneficiario", sortable: false },
        { text: "Vencimento", value: "DataVencimento", sortable: false },
        { text: "Pagamento", value: "DataLiquidacao", sortable: false },
        {
          text: "Valor",
          value: "ValorPagamentoAtualizadoFormatado",
          sortable: false,
          align: "right",
        },
        {
          text: "",
          value: "pagto_id",
          sortable: false,
          align: "center",
        },
      ],
      dadosComprovanteBoleto: [],
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.moduloInfo();
    this.getSaldo();
    this.dadosPagador = Auth.getUserData();
    this.telefone = this.dadosPagador.telefone_mask;

    if (
      sessionStorage.requestCodeSMSBoleto == undefined ||
      sessionStorage.requestCodeSMSBoleto == null
    ) {
      this.requestCodeSMSBoleto = true;
    } else {
      if (sessionStorage.requestCodeSMSBoleto) {
        this.requestCodeSMSBoleto = false;
      }
    }
  },

  methods: {
    Comprovante(id: string) {
      Overlay.show();

      Api.get("/v2/public/comprovantes/boleto/" + id)
        .then((response) => {
          this.dadosComprovanteBoleto = response.data.body;
          this.dialogComprovanteBoleto = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    openDialogPin(id: number) {
      this.dialogPIN = true;
      this.$nextTick(() => {
        this.focusPin();
      });
    },
    closeDialogPin() {
      (this.pinCode = ""), (this.dialogPIN = false);
    },
    focusPin() {
      this.$nextTick(() => {
        const wrapper = this.$refs.pincode as any;

        window.setTimeout(() => wrapper?.focus(), 0);
      });
    },
    alteraValorPagamento() {
      if (
        this.valorPagamento.toString() === "0.00" ||
        this.valorPagamento.toString() === "0,00"
      ) {
        return false;
      }
      this.dialogAlteraValorBoleto = false;
      this.stepConfirmValidadeBoleto = true;
    },
    confirmaValidadeBoleto() {
      if (
        this.valorPagamento.toString() === "0.00" ||
        this.valorPagamento.toString() === "0,00"
      ) {
        this.dialogAlteraValorBoleto = true;
        return false;
      } else {
        this.stepConfirmValidadeBoleto = true;
      }
    },
    cancelaPagamento() {
      this.stepConfirmValidadeBoleto = false;
      this.stepper = 1;
      this.stepPagto = 1;
      this.dadosBoleto = [];
      this.dialogAlteraValorBoleto = false;
      this.dialogRequestPinPagtoBoleto = false;
      this.dialogPIN = false;
      this.pagtoToken = "";
      this.pinCode = "";
      this.codigoBarras = "";
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    moduloInfo() {
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/client/pagamentos/info")
        .then((response) => {
          this.ModuloInfo = response.data.body.moduloInfo;
          this.pagamentosRecentes = response.data.body.pagamentosRecentes;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    getSaldo() {
      Overlay.show();
      Api.get("/v2/client/financeiro/saldo")
        .then((response) => {
          this.saldoCliente = response.data.body.saldoFormatado;
        })

        .finally(() => {
          Overlay.hide();
        });
    },
    validaCodigoBarra() {
      if (this.codigoBarras.length < 30) {
        Snackbar.show("Informe o código de barras corretamente", "error");
        return false;
      }
      Overlay.show();
      this.btnLoading = true;

      Api.post("/v2/client/pagamentos/validacao", {
        linhaDigitavel: this.codigoBarras,
      })
        .then((response) => {
          this.dadosBoleto = response.data.body;

          if (
            this.dadosBoleto.ProcessaTransacaoResponse.ProcessaTransacaoResult
              .DadosRegistro.ValorPagamentoAtualizado >
            this.dadosBoleto.ProcessaTransacaoResponse
              .ProcessaTransacaoResultValor
          ) {
            this.valorPagamento =
              this.dadosBoleto.ProcessaTransacaoResponse.ProcessaTransacaoResult.DadosRegistro.DadosRegistro.ValorPagamentoAtualizado;
          } else {
            this.valorPagamento =
              this.dadosBoleto.ProcessaTransacaoResponse.ProcessaTransacaoResult.Valor;
          }

          this.stepper = 1;
          this.stepPagto = 2;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          Overlay.hide();

          this.btnLoading = false;
        });
    },
    validaSMSBoleto() {
      if (this.codigoValidacaoPagto.length != 6) {
        Snackbar.show("O Código de validação possui 6 números", "error");
        return false;
      }
      Overlay.show();
      this.btnLoading = true;

      Api.post("/v2/client/pagamentos/checkpinsms", {
        code: this.codigoValidacaoPagto,
      })
        .then((response) => {
          sessionStorage.setItem("requestCodeSMSBoleto", "false");
          this.dialogRequestPinPagtoBoleto = false;
          this.stepper = 2;
          this.stepPagto = 3;
          Snackbar.show(response.data.body.message);
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          Overlay.hide();

          this.btnLoading = false;
        });
    },
    registraBoleto() {
      Overlay.show();
      this.btnLoading = true;

      Api.post("/v2/client/pagamentos/registra", {
        jsonConsultaBoleto: this.dadosBoleto,
        valorPagamento: this.valorPagamento,
        descricaoPagamento: this.descricaoPagto,
        requestCodeSMSBoleto: this.requestCodeSMSBoleto,
      })
        .then((response) => {
          this.pagtoToken = response.data.body.tokenPagamento;

          if (response.data.body.sendCode) {
            this.dialogRequestPinPagtoBoleto = true;
          } else {
            this.stepper = 2;
            this.stepPagto = 3;
          }
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          Overlay.hide();

          this.btnLoading = false;
        });
    },

    efetuaPagamento() {
      this.dialogPIN = false;
      Overlay.show();
      this.btnLoading = true;

      Api.post("/v2/client/pagamentos/efetuaPagamento", {
        tokenPagamento: this.pagtoToken,
        transactionPin: this.pinCode,
      })
        .then((response) => {
          this.pagtoToken = "";
          this.stepper = 1;
          this.stepConfirmValidadeBoleto = false;
          this.stepPagto = 1;
          this.dialogPIN = false;
          this.descricaoPagto = "";
          this.pinCode = "";
          this.codigoBarras = "";
          this.dialogAlteraValorBoleto = false;
          this.dialogRequestPinPagtoBoleto = false;
          this.dialogComprovantePagamento = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          Overlay.hide();

          this.btnLoading = false;
        });
    },
    formataValor(data: number) {
      const valor = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(data);

      return valor.toString().replace("R$", "").trim();
    },
  },
  directives: { money: VMoney },
});
