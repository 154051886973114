import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import router from "@/router";
import Auth from "../lib/Auth";

class ApiMobileService {
  init() {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_MOBILE,
    });
    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config: AxiosRequestConfig) {
      const token: string = sessionStorage.token;

      if (config.headers != null) {
        config.headers.Authorization = token ? `Bearer ${token}` : "";
      }

      return config;
    });

    // Add a response interceptor
    instance.interceptors.response.use(
      function (response: AxiosResponse) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        const status = Number(error.response.status);

        if (status === 401) {
          // logout here
          // Auth.logout();
        }

        if (status >= 500) {
          alert(
            "Nossos servidores estão sobrecarregados, tente novamente em instantes..."
          );
        }

        return Promise.reject(error);
      }
    );

    return instance;
  }
}

export default new ApiMobileService().init();
