
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import { TableOptions } from "@/models/TableOptions";

export interface ResumoGGRTable {
  dataBr?: string;
  nome?: string;
  email?: string;
  documento?: string;
  qtd?: string;
  totalFormatado?: string;
}

export interface TableHeader {
  text: string;
  value: string;
  sortable: boolean;
  align?: string;
}

export default Vue.extend({
  name: "ResumoGGR",

  data() {
    return {
      menuPeriodo: false,
      hasLoadedData: false,
      loadingData: false,
      search: "",
      loadingDesserts: false,
      lang: navigator.language,
      stats: [],
      errors: [],
      moment: moment,
      isMobile: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      options: {} as TableOptions,
      periodo: moment().format("YYYY-MM-DD"),
      maxDate: moment().format("YYYY-MM-DD"),
      headers: [] as TableHeader[], // Inicialmente vazio com tipo definido
      desserts: new Array<ResumoGGRTable>(),
      totalDesserts: 0,
      timeout: 0,
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "GGR",
          disabled: false,
          href: "/ggr",
        },
        {
          text: "Resumo",
          disabled: true,
          href: "/ggr/resumo",
        },
      ],
    };
  },

  created() {
    this.isMobile = this.detectMobile();
    this.updateHeaders(); // Chama a atualização dos cabeçalhos na criação
  },

  watch: {
    '$i18n.locale': {
      handler() {
        this.updateHeaders();
        this.updateBreadcrumbs();
      },
      immediate: true
    }
  },

  methods: {
    updateHeaders() {
      this.headers = [
        { text: this.$t("components.preCadastro.template.form.Affiliates") as string, value: "nome", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Document") as string, value: "documento", sortable: false },
        { text: "E-mail", value: "email", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Transaction") as string, value: "qtd", sortable: false, align: "center" },
        { text: this.$t("components.preCadastro.template.form.Valuebase") as string, value: "totalRefFormatado", sortable: false, align: "right" },
        { text: this.$t("components.preCadastro.template.form.ValueGRR") as string, value: "totalFormatado", sortable: false, align: "right" }
      ];
    },

    updateBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "GGR",
          disabled: false,
          href: "/ggr",
        },
        {
          text: this.$t("components.preCadastro.template.form.Summary") as string,
          disabled: true,
          href: "/ggr/resumo",
        },
      ];
    },

    next(page: number) {
      this.page = page;
      this.loadDatas();
    },

    doDelayedSearch() {
      this.page = 1;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.loadDatas();
      }, 1000);
    },

    detectMobile() {
      return screen.width <= 760;
    },

    loadDatas() {
      this.hasLoadedData = true;
      Overlay.show();

      this.loadingData = true;
      this.loadingDesserts = true;
      Api.get("/v2/client/ggr/resumo", {
        params: {
          query: this.search,
          startDate: this.periodo,
        },
      })
        .then((response) => {
          this.desserts = response.data.body.rows as ResumoGGRTable[];
          this.totalDesserts = Number(response.data.body.count.__total);
          this.loadingDesserts = false;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          if (response.status !== 200) {
            Snackbar.show(response.data.body.error, "error");
          }
        })
        .finally(() => {
          Overlay.hide();
        });
    },
  },
});
