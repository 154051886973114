
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import AtividadesRecentes from "@/views/seguranca/AtividadesRecentes.vue";
import AlterarSenhaPIN from "@/views/seguranca/AlterarSenhaPIN.vue";
import Dispositivos from "@/views/seguranca/Dispositivos.vue";
import TwoAuthenticator from "@/views/seguranca/TwoAuthenticator.vue";
import Swal from "sweetalert2";
export default Vue.extend({
  name: "SegurancaHome",

  data() {
    return {
      loadingData: true,

      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    //this.moduloInfo();
  },
  components: {
    AtividadesRecentes,
    AlterarSenhaPIN,
    Dispositivos,
    TwoAuthenticator,
  },
  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
});
