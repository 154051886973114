
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { PerfilDadosEndereco } from "@/models/ProfileDadosEndereco";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import VueMask from "v-mask";
import router from "@/router";
Vue.use(VueMask);

export default Vue.extend({
  name: "PerfilDadosEndereco",

  data() {
    return {
      valid: true,
      btnLoading: false,
      loadingData: true,
      lang: navigator.language,
      errors: [],
      moment: moment,
      formEdit: {} as PerfilDadosEndereco,
      readonlyCidade: false,
      readonlyUF: false,
      originalCEP: "",
      cepDisabled: true,
    };
  },
  created() {
    this.showDadosBasicos();
  },

  methods: {
    showDadosBasicos() {
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/client/profile/show")
        .then((response) => {
          this.formEdit = response.data.body.endereco;
          this.originalCEP = response.data.body.endereco.cep;

          this.loadingData = false;
        })

        .finally(() => {
          Overlay.hide();
        });
    },

    onSubmit() {
      this.errors = [];
      this.btnLoading = true;
      Overlay.show();
      Api.put("/v2/client/profile/update-address", this.formEdit)
        .then((response) => {
          Snackbar.show("Endereço atualizado com sucesso!");
          router.push("/perfil");
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          this.btnLoading = false;
          Overlay.hide();
        });
    },
    checkCEP: function (token: string) {
      this.errors = [];

      if (
        this.originalCEP.replace("-", "") == this.formEdit.cep.replace("-", "")
      ) {
        return false;
      }
      if (this.formEdit.cep.length == 9) {
        this.btnLoading = true;
        Overlay.show();
        Api.post(
          "/v2/client/register/checkCEP?internal=true",
          { cep: this.formEdit.cep },
          {
            headers: {
              recaptcha: token,
            },
          }
        )
          .then((response) => {
            this.formEdit.cidade = response.data.body.localidade;
            this.formEdit.uf = response.data.body.uf;
            if (response.data.body.logradouro.length > 5) {
              this.formEdit.logradouro = response.data.body.logradouro;
            }
            if (this.formEdit.cidade.length > 3) {
              this.readonlyCidade = true;
            } else {
              this.readonlyCidade = false;
            }
            if (this.formEdit.uf.length == 2) {
              this.readonlyUF = true;
            } else {
              this.readonlyUF = false;
            }
            this.$nextTick(() => {
              const wrapper = this.$refs.refLogradouro as any;

              window.setTimeout(() => wrapper?.focus(), 0);
            });
          })
          .catch((error: any) => {
            const response = error.response as AxiosResponse;
            const status = response.status;
            const data = response.data;

            if (status === 422) {
              this.errors = data.body.errors;
            } else {
              Swal.fire({
                text: data.body.error,
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
            }
          })
          .finally(() => {
            this.btnLoading = false;
            //  console.log("finalizou");

            Overlay.hide();
          });
      }
    },
  },
});
