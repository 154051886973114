
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "App",
  watch: {
    '$i18n.locale': function() {
      if (this.$route.meta && this.$route.meta.title) {
        document.title = this.$t(this.$route.meta.title) + " - " + process.env.VUE_APP_NAME;
      }
    }
  },
});
