import Vue from "vue";
import VueI18n from "vue-i18n";
import getBrowserLocale from "./util/i18n/get-browser-locale";
import { supportedLocalesInclude } from "./util/i18n/supported-locales";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = (require as any).context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const messages: Record<string, any> = {};

  locales.keys().forEach((key: string) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);

    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });
  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  } else {
    return process.env.VUE_APP_I18N_LOCALE || "pt";
  }
}

export default new VueI18n({
  locale: localStorage.getItem("selectedLanguage") || "pt",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "pt",
  messages: loadLocaleMessages(),
});
