
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import QRCode from "qrcode";
import Swal from "sweetalert2";
import NewSwapSale from "../swaps/SwapsNewSell.vue";
import Auth from "@/lib/Auth";
import moment from "moment";
import { TableOptions } from "@/models/TableOptions";
import { Snackbar } from "@/lib/Snackbar";
import router from "@/router";
import { DepositoPix } from "@/models/DepositoPix";

export interface Swaps {
  id: string;
  swap: {
    currencyIn: {
      name: string;
      currencySymbol: string;
    };
    formattedValue: any;
    status: {
      key: any;
    };
    updatedAt: any;
    networkIn: {
      nameId: string;
      currencySymbol: string;
      description: string;
    };
    amountIn: string;
  };
  // Adicione outras propriedades se necessário
}
interface AddressDeposit {
  address: string;
  amountIn: string;
  netAmountOut: string;
}

interface PixData {
  qrCodeImage: string;
  valorFormatado: string;
  codigoQR: string;
  swapId: string;
  status: string;
  updatedAt: string;
  address: AddressDeposit[];
  currencyConsult: '', 
  contractType: '', // Adiciona a propriedade address
}


export default Vue.extend({
  name: "UserSwaps",
  props: {
    item: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      btnLoading: false,
      loadingBaseData: true,
      loadingQRCode: false,
      moduloAtivo: true,
      dialogPix: false,
      form: {} as DepositoPix,
      dialogPIN: false,
      isMobile: false,
      idPixChecking: "",
      ckStatusPagto: false,
      progressTempoCkPix: 0,
      progressCkPixShow: true,
      progressQueryPix: false,
      progressPixCkinterval: 0,
      PixData: {} as PixData,
      amountIn: '',
      currencyInName: '',
      contractType: '',
      networkIn: '',

      networkInDescription: '',
      swapId: '',
      user: "",
      totalResults: 0,
      breadcrumbs: [{
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.Crypto"),
          disabled: false,
          href: "/cripto",
        },
        {
          text: this.$t("components.preCadastro.template.form.SwapsSell"),
          disabled: true,
          href: "/cripto/swaps/sell",
        },
      ],
      //   {
      //     text: this.$t("components.preCadastro.template.form.Home"),
      //     disabled: false,
      //     href: "/",
      //   },
      //   {
      //     text: this.$t("components.preCadastro.template.form.Crypto"), 
      //     disabled: false,
      //     href: "/cripto",
      //   },
      //   {
      //     text: this.$t("components.preCadastro.template.form.SwapsSell"),
      //     disabled: true,
      //     href: "/cripto/swaps/sell",
      //   },
      // ],
      showSaldo: false,
      loadingDesserts: true,
      totalDesserts: 0,
      options: {} as TableOptions,
      search: "",
      moment: moment,
      lang: navigator.language,
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      timeout: 0,
      dessertsSwaps: new Array < Swaps > (),
      headersSwaps: [] as Array < {
        text: string;value: string;sortable: boolean
      } > ,
      ModuloInfo: [] as any,
    };
  },
  created() {
    if (!Auth.mfaEnabled) {
      router.push("/");
    } else {
      this.getInfo();
    }
    this.headersSwaps = this.getTranslatedHeaders();
  },
  components: {
    NewSwapSale,
  },

  watch: {
    '$i18n.locale': function() {
      this.updateHeaders();
      this.updateBreadcrumbs();
    }
  },
  methods: {
    onPageChange(newPage: number) {
      console.log("Página alterada para:", newPage); 
    this.page = newPage;
    this.getSwaps(); // Chame o método que faz a requisição de dados
  },
    formatAmountIn(_amount: string) {
    const amount = Number(Number(_amount).toFixed(6));
    return amount.toString();
  },
    getCurrencyIcon(currencyConsult: string) {
      switch (currencyConsult.toUpperCase()) {
        case 'BTC':
          return require('@/assets/images/btc.svg');
        case 'USDC':
          return require('@/assets/images/usdc.svg');
        case 'USDT':
          return require('@/assets/images/usdt.svg');
        case 'TRX':
          return require('@/assets/images/trx.svg');
        case 'ETH':
          return require('@/assets/images/eth.svg');
        default:
          return '';
      }
    },
    getTranslatedBreadcrumbs() {
      return [{
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.Crypto"),
          disabled: false,
          href: "/cripto",
        },
        {
          text: this.$t("components.preCadastro.template.form.SwapsSell"),
          disabled: true,
          href: "/cripto/swaps/sell",
        },
      ];
    },

    updateBreadcrumbs() {
      this.breadcrumbs = [{
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.Crypto"),
          disabled: false,
          href: "/cripto",
        },
        {
          text: this.$t("components.preCadastro.template.form.SwapsSell"),
          disabled: true,
          href: "/cripto/swaps/sell",
        },
      ];
    },
    queryAndIndeterminate() {
      // Certifica-se de que nenhum outro intervalo está rodando
      if (this.progressPixCkinterval) {
        clearInterval(this.progressPixCkinterval); // Limpa o intervalo anterior
      }

      if (this.ckStatusPagto) {
        this.progressQueryPix = true;
        this.progressCkPixShow = true;
        this.progressTempoCkPix = 0;

        setTimeout(() => {
          this.progressQueryPix = false;

          // Inicia o intervalo de progresso
          this.progressPixCkinterval = setInterval(() => {
            if (this.progressTempoCkPix >= 100) {
              if (this.ckStatusPagto) {
                this.checkStatusPagamento(); // Verifica o status do pagamento
              }
              clearInterval(this.progressPixCkinterval); // Limpa o intervalo ao atingir 100%
              this.progressCkPixShow = false;

              // Reinicia o ciclo de verificação
              setTimeout(() => this.queryAndIndeterminate(), 1000);
            } else {
              // Incrementa o progresso suavemente
              this.progressTempoCkPix += 1;
            }
          }, 100); // Aumenta suavemente o progresso a cada 100ms
        }, 1000); // Espera 1 segundo antes de iniciar
      }
    },
    checkStatusPagamento() {
      Overlay.show();
      //nao existe endpoint para verificar status de pagamento relacionada a essa swap
    },
    getAddress(): string {
      return this.PixData.address.length > 0 ? this.PixData.address[0].address : '';
    },
    getTranslatedHeaders() {
      return [{
          text: this.$t("components.preCadastro.template.form.SourceCurrency").toString(),
          value: "swap.currencyIn",
          sortable: false
        },
        {
          text: this.$t("components.preCadastro.template.form.TargetCurrency").toString(),
          value: "swap.currencyOut",
          sortable: false
        },
        {
          text: this.$t("components.preCadastro.template.form.Input").toString(),
          value: "swap.amountIn",
          sortable: false
        },
        {
          text: this.$t("components.preCadastro.template.form.Output").toString(),
          value: "swap.netAmountOut",
          sortable: false
        },
        {
          text: this.$t("components.preCadastro.template.form.Fees").toString(),
          value: "swap.fees",
          sortable: false
        },
        {
          text: this.$t("components.preCadastro.template.form.Rate").toString(),
          value: "swap.price.amount",
          sortable: false
        },
        {
          text: this.$t("components.preCadastro.template.form.Status").toString(),
          value: "swap.status",
          sortable: false
        },
      ];
    },
    updateHeaders() {
      this.headersSwaps = this.getTranslatedHeaders();
    },

    async openDialogPix(item: Swaps) {
      // Se o pagamento foi cancelado, não abrir o QR Code
      if (item.swap.status.key === 'CANCELLED') {
        Swal.fire({
          text: "Pagamento cancelado, não é possível abrir o QR Code.",
          icon: "error",
          confirmButtonText: "Ok",
        });
        return;
      }
      if (item.swap.status.key === 'EXPIRED') {
        Swal.fire({
          text: "Pagamento expirado, não é possível abrir o QR Code.",
          icon: "error",
          confirmButtonText: "Ok",
        });
        return;
      }

      // Não verifica o localStorage quando o usuário clica manualmente
      this.dialogPix = true;
      this.loadingQRCode = true;
      //this.ckStatusPagto = true;
      //this.queryAndIndeterminate();  //desativado por falta de endpoint para chegar status de pagamento

      try {
        const swapId = item.id;

        if (!swapId) {
          console.error("swapId não está definido");
          Swal.fire({
            text: "Erro ao obter o ID da transação. Por favor, tente novamente.",
            icon: "error",
            confirmButtonText: "Ok",
          });
          this.loadingQRCode = false;
          return;
        }

        const currencySymbol = item.swap?.networkIn?.currencySymbol || 'BTC';

        const response = await Api.get(`/v2/client/cripto/swaps/address-deposit/?type=sell&currencyIn=${currencySymbol}&swapId=${swapId}`);

        const addressData = response.data.address;
        const qrCodeImage = await QRCode.toDataURL(addressData.address);

        this.PixData = {
          qrCodeImage,
          valorFormatado: item.swap.formattedValue,
          codigoQR: addressData.address,
          swapId: swapId,
          status: item.swap.status.key,
          updatedAt: item.swap.updatedAt,
          address: response.data.address || [],
          currencyConsult: response.data.currencyConsult || '', 
          contractType: response.data.contractType || '', 
        };

        this.amountIn = item.swap.amountIn;
        this.currencyInName = item.swap.currencyIn.name;
        this.networkIn = item.swap.networkIn.nameId;
        this.networkInDescription = item.swap.networkIn.description;

        this.dialogPix = true;

      } catch (error) {
        console.error("Erro ao gerar o QR Code", error);
        Swal.fire({
          text: "Erro ao gerar o QR Code. Por favor, tente novamente.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } finally {
        this.loadingQRCode = false;
      }
  },
  closeDialogPix() {
      this.form.valor = "0";
      this.dialogPix = false; // Fecha o pop-up
      this.ckStatusPagto = false;

       // Armazena no localStorage que o usuário fechou o pop-up
  localStorage.setItem('dialogPixClosed', 'true');
    },
    resetDialogState() {
  localStorage.removeItem('dialogPixClosed'); // Reseta o estado do pop-up
},
    requestUse() {
      this.btnLoading = true;
      Overlay.show();
      Api.get("/v2/client/cripto/swaps/reverse")
        .then((response) => {
          Snackbar.show(response.data.body, "success");
          this.getInfo();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            this.moduloAtivo = false;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          Overlay.hide();
          this.btnLoading = false;
          this.loadingBaseData = false;
        });
    },
    getInfo() {
      this.loadingBaseData = true;
      Overlay.show();
      sessionStorage.removeItem("userCriptoId");

      Api.get("/v2/client/cripto/swaps/info")
        .then((response) => {
          this.user = response.data.body.id;


          this.ModuloInfo = response.data.body;

          sessionStorage.setItem("userCriptoId", response.data.body.id);

          if (this.user) {
            this.getSwaps();
          }
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            this.moduloAtivo = false;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          Overlay.hide();
          this.loadingBaseData = false;
        });
    },
    doCopy() {
      const addressDeposit = this.PixData.codigoQR;

      if (!addressDeposit) {
        Snackbar.show(this.$t("components.preCadastro.template.form.CopyError") as string, "error");
        return;
      }

      navigator.clipboard.writeText(addressDeposit).then(() => {
        Snackbar.show(this.$t("components.preCadastro.template.form.CopiedSuccessfully") as string);
      }).catch(() => {
        Snackbar.show(this.$t("components.preCadastro.template.form.CopyError") as string, "error");
      });
    },
    onCopy() {
      Snackbar.show(this.$t("components.preCadastro.template.form.CopiedSuccessfully") as string);
    },
    onCopyError() {
      Snackbar.show(this.$t("components.preCadastro.template.form.CopyError") as string, "error");
    },
    getSwaps() {
  console.log("Carregando dados da página:", this.page);
  if (!this.user) return;
  const { page } = this.options;

  this.dessertsSwaps = []; // Limpa os dados antigos
  this.loadingDesserts = true;
  Overlay.show();

  Api.get(`/v2/client/cripto/swaps/reverse/index/${this.user}`, {
      params: {
        type: 'sell',
        page: this.page,
        limit: this.itemsPerPage,
      },
    })
    .then((response) => {
      if (response.data.data.rows.length > 0) {
        this.swapId = response.data.data.rows[0].id; // Armazena o id do primeiro item

        const isNewSwap = response.data.data.rows[0].swap.status.key === 'PENDING';

        // Verifica no localStorage se o pop-up já foi fechado
        const dialogWasClosed = localStorage.getItem('dialogPixClosed');

        if (isNewSwap && !dialogWasClosed) {
          // Exibe um alerta para o usuário antes de abrir o diálogo Pix
          Swal.fire({
            title: this.$t('components.preCadastro.template.form.NewSwapDetected') as string,
            text: this.$t('components.preCadastro.template.form.PleaseClickOKToProceed') as string,
            icon: "info",
            confirmButtonText: "OK",
          }).then(() => {
            // Abre o diálogo Pix após o usuário clicar em OK
            this.openDialogPix(response.data.data.rows[0]);
          });
        }
      }

      this.dessertsSwaps = response.data.data.rows; // Atualiza os dados da tabela
      this.totalResults = response.data.data.count; // Atualiza o número total de resultados
      this.totalDesserts = Number(response.data.data.count); // Atualiza o número total de itens
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      Overlay.hide();
      this.loadingDesserts = false;
    });
},

    getCurrencySymbol(symbol: string) {
      if (symbol === "p2p") {
        symbol = "brl";
      }
      return require(`@/assets/images/${symbol}.svg`);
    },
    getPrintableStatus(status: any) {
      let html = "";
      var icon = "<span></span>";
      html += '<div class="d-flex">';

      if (status.key === "COMPLETED") {
        icon =
          '<span size="14" color="text--success" >mdi mdi-check-circle</span>';
      }
      html += "<div>" + icon + "</div>";
      html += "<div>" + status.namePt + "</div>";
      html += "</div>";

      return html;
    },
  },

});
