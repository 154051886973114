
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import { TableOptions } from "@/models/TableOptions";

export interface ExtratoGGRTable {
  transacao?: string;
  dataBr?: string;
  nome?: string;
  valorFormatado?: string;
  historico?: string;
}

export interface TableHeader {
  text: string | any;
  value: string;
  sortable: boolean;
  align?: string;
}

export default Vue.extend({
  name: "ExtratoGGR",
  data() {
    return {
      hasLoadedData: false,
      loadingData: false,
      search: "",
      loadingDesserts: false,
      lang: navigator.language,
      stats: [],
      errors: [],
      moment: moment,
      isMobile: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      options: {} as TableOptions,
      startPeriod: moment().add(-1, "hours").format("YYYY-MM-DDTHH:mm"),
      endPeriod: moment().format("YYYY-MM-DDT23:59"),
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      defaultStartDate: moment().format("YYYY-MM-DD"),
      defaultEndDate: moment().format("YYYY-MM-DD"),
      maxDate: moment().format("YYYY-MM-DD"),
      headers: [] as TableHeader[],
      desserts: new Array<ExtratoGGRTable>(),
      totalDesserts: 0,
      timeout: 0,
      breadcrumbs: [
        {
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: "GGR",
          disabled: false,
          href: "/ggr",
        },
        {
          text: this.$t("components.preCadastro.template.form.extract"),
          disabled: true,
          href: "/ggr/extrato",
        },
      ],
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.updateHeaders();
    this.updateBreadcrumbs();
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.updateHeaders();
        this.updateBreadcrumbs();
      },
      immediate: true
    },
    options: {
      handler() {
        this.loadDatas();
      },
    },
    search: {
      handler() {
        this.doDelayedSearch();
      },
    },
  },
  methods: {
    translatedHeaders(): TableHeader[] {
      return [
        { text: this.$t('components.preCadastro.template.form.Data') as string, value: 'dataBr', sortable: false },
        { text: this.$t('components.preCadastro.template.form.Affiliates') as string, value: 'nome', sortable: false },
        { text: this.$t('components.preCadastro.template.form.Referencia') as string, value: 'ref', sortable: false },
        { text: this.$t('components.preCadastro.template.form.Valuebase') as string, value: 'valorRefFormatado', align: 'right', sortable: false },
        { text: this.$t('components.preCadastro.template.form.ValueGRR') as string, value: 'valorFormatado', align: 'right', sortable: false },
      ];
    },
    updateHeaders() {
      this.headers = this.translatedHeaders();
    },
    updateBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: "GGR",
          disabled: false,
          href: "/ggr",
        },
        {
          text: this.$t("components.preCadastro.template.form.extract"),
          disabled: true,
          href: "/ggr/extrato",
        },
      ];
    },
    next(page: number) {
      this.page = page;
      this.loadDatas();
    },
    doDelayedSearch() {
      this.page = 1;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.loadDatas();
      }, 1000);
    },
    detectMobile() {
      return screen.width <= 760;
    },
    loadDatas() {
      const intervalo = Math.round(
        (new Date(this.endPeriod).getTime() -
          new Date(this.startPeriod).getTime()) /
          (1000 * 3600 * 24)
      );

      if (intervalo > 31) {
        Snackbar.show(
          "O intervalo de pesquisa não pode ser superior a 31 dias",
          "error"
        );
        return false;
      }
      let entryType = "C";
      if (
        Number(sessionStorage.ggr) != 0 ||
        sessionStorage.ggr == undefined ||
        sessionStorage.ggr == null
      ) {
        entryType = "D";
      }

      this.hasLoadedData = true;
      Overlay.show();

      this.loadingData = true;

      this.loadingDesserts = true;
      Api.get("/v2/client/ggr/extrato", {
        params: {
          query: this.search,
          startDate: this.startPeriod,
          endDate: this.endPeriod,
          page: this.page,
          entryType: entryType,
        },
      })
        .then((response) => {
          this.desserts = response.data.body.rows as ExtratoGGRTable[];

          this.totalDesserts = Number(response.data.body.count.__total);
          this.loadingDesserts = false;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;

          if (status != 200) {
            Snackbar.show(response.data.body.error, "error");
          }
        })
        .finally(() => {
          Overlay.hide();
        });
    },
  },
});
