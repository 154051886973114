
import Vue from "vue";

import Api from "@/services/Api";
import { Login } from "@/models/Login";
import Axios, { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import { Overlay } from "@/lib/Overlay";
import router from "@/router";

export default Vue.extend({
  name: "Login",
  data: () => ({
    loading: false,
    valid: true,

    token: "",
  }),

  mounted() {
    this.validarEmail();
  },
  created() {
    //  this.onSubmit();
  },

  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    validarEmail() {
      const id = this.$route.params.id;
      this.loading = true;
      Api.post("v2/client/validaremail", { token: id })
        .then((response) => {
          Swal.fire({
            text: response.data.body.message,
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          }).then((result) => {
            router.push("/login");
          });
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
        })
        .finally(() => {
          Overlay.hide();
          this.loading = false;
        });
    },
  },
});
