
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import Swal from "sweetalert2";

import NewInvoices from "@/views/cripto/swaps/SwapsNewInvoices.vue";
import Auth from "@/lib/Auth";
import moment from "moment";
import { TableOptions } from "@/models/TableOptions";
import { Snackbar } from "@/lib/Snackbar";
import router from "@/router";

export interface Swaps {
  id: string;
  swap: any;
}
export default Vue.extend({
  name: "UserSwaps",

  data() {
    return {
      btnLoading: false,
      loadingBaseData: true,
      moduloAtivo: true,
      breadcrumbs: [
        {
          text: this.$t("components.preCadastro.template.form.Home"), 
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.Crypto"), 
          disabled: false,
          href: "/cripto",
        },
        {
          text: this.$t("components.preCadastro.template.form.Invoices"), 
          disabled: true,
          href: "/cripto/swaps/sell",
        },
      ],
      showSaldo: false,
      loadingDesserts: true,
      totalDesserts: 0,
      options: {} as TableOptions,
      search: "",
      moment: moment,
      lang: navigator.language,
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      timeout: 0,
      user: "",
      dessertsSwaps: new Array<Swaps>(),
      headersSwaps: [
        { text: this.$t("components.preCadastro.template.form.CurrencyIn"), value: "swap.currencyIn", sortable: false },
        { text: this.$t("components.preCadastro.template.form.CurrencyOut"), value: "swap.currencyOut", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Entry"), value: "swap.amountIn", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Exit"), value: "swap.netAmountOut", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Fees"), value: "swap.fees", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Rate"), value: "swap.rate.amount", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Status"), value: "swap.status", sortable: false },
      ],
      ModuloInfo: [],
    };
  },
  created() {
    if (!Auth.mfaEnabled) {
      router.push("/");
    } else {
      this.getInfo();
    }
  },
  components: {
    NewInvoices,
  },

  methods: {
    requestUse() {
      this.btnLoading = true;
      Overlay.show();
      Api.post("/v2/client/cripto/swaps/requestUse")
        .then((response) => {
          Snackbar.show(response.data.body, "success");
          this.getInfo();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            this.moduloAtivo = false;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          Overlay.hide();
          this.btnLoading = false;
          this.loadingBaseData = false;
        });
    },
    getInfo() {
      this.loadingBaseData = true;
      Overlay.show();
      sessionStorage.removeItem("userCriptoId");
      Api.get("/v2/client/cripto/swaps/info")
        .then((response) => {
          this.user = response.data.body.id;
          this.ModuloInfo = response.data.body;

          sessionStorage.setItem("userCriptoId", response.data.body.id);

          if (this.user != null) {
            this.getSwaps();
          }
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            this.moduloAtivo = false;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          Overlay.hide();
          this.loadingBaseData = false;
        });
    },
    getSwaps() {
      const { page } = this.options;
      this.loadingDesserts = true;
      Overlay.show();
      Api.get("/v2/client/cripto/swaps/index/" + this.user, {
        params: {
          page: page,
        },
      })
        .then((response) => {
          this.dessertsSwaps = response.data.data.rows as Swaps[];

          this.totalDesserts = Number(response.data.data.count);
        })
        .finally(() => {
          Overlay.hide();
          this.loadingDesserts = false;
        });
    },
    getCurrencySymbol(symbol: string) {
      return require(`@/assets/images/${symbol}.svg`);
    },
    getPrintableStatus(status: any) {
      let html = "";
      var icon = "<span></span>";
      html += '<div class="d-flex">';

      if (status.key === "COMPLETED") {
        icon =
          '<span size="14" color="text--success" >mdi mdi-check-circle</span>';
      }
      html += "<div>" + icon + "</div>";
      html += "<div>" + status.namePt + "</div>";
      html += "</div>";

      return html;
    },
  },
});
