
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
export default Vue.extend({
  name: "Dispositivos",

  data() {
    return {
      isMobile: false,
      dialogDeviceManager: false,
      listDevices: [],
      listDevicesCount: 0,
      lang: navigator.language,
      errors: [],
      moment: moment,
    };
  },

  methods: {
    isAtualDevice(token: string) {
      if (sessionStorage.getItem("_vid") === token) {
        return true;
      } else {
        return false;
      }
    },
    getDevices() {
      Overlay.show();

      Api.get("/v2/client/profile/security/getDevices")
        .then((response) => {
          this.listDevices = response.data.body.rows;
          this.listDevicesCount = response.data.body.count;
          this.dialogDeviceManager = true;
        })

        .finally(() => {
          Overlay.hide();
        });
    },
  },
});
